import React from 'react';
import {
  ANNOTATION_EDIT_URL,
  UserEntity,
  RELATION_MEMBER_OF,
} from '@backstage/catalog-model';
import {
  Avatar,
  InfoCard,
  InfoCardVariants,
  Link,
} from '@backstage/core-components';
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import {
  EntityRefLinks,
  useEntity,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import Alert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import { LinksGroup } from '../Meta/LinksGroup';
import { UserMembershipHierarchyBreadcrumbs } from '@celonis/plugin-group-hierarchy';

const CardTitle: React.FC<React.PropsWithChildren> = props => (
  <Box display="flex" alignItems="center">
    <PersonIcon fontSize="inherit" />
    <Box ml={1}>{props.children}</Box>
  </Box>
);

/** @public */
export const UserProfileCard = (props: {
  variant?: InfoCardVariants;
  showLinks?: boolean;
}) => {
  const { entity: user } = useEntity<UserEntity>();
  if (!user) {
    return <Alert severity="error">User not found</Alert>;
  }

  const entityMetadataEditUrl =
    user.metadata.annotations?.[ANNOTATION_EDIT_URL];

  const {
    metadata: { name: metaName, description, links },
    spec: { profile },
  } = user;
  const displayName = profile?.displayName ?? metaName;
  const emailHref = profile?.email ? `mailto:${profile.email}` : '';
  const tags = user.metadata.tags ?? [];

  const memberOfRelations = getEntityRelations(user, RELATION_MEMBER_OF);

  return (
    <InfoCard
      title={<CardTitle>{displayName}</CardTitle>}
      subheader={description}
      variant={props.variant}
      action={
        <>
          {entityMetadataEditUrl && (
            <IconButton
              aria-label="Edit"
              title="Edit Metadata"
              component={Link}
              to={entityMetadataEditUrl}
            >
              <EditIcon />
            </IconButton>
          )}
        </>
      }
    >
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12} sm={2} xl={1}>
          <Avatar displayName={displayName} picture={profile?.picture} />
        </Grid>

        <Grid item md={10} xl={11}>
          <List>
            {/* Show EMAIL */}
            {profile?.email && (
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Email">
                    <EmailIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>
                  <Link to={emailHref}>{profile.email}</Link>
                </ListItemText>
              </ListItem>
            )}

            {/* Show Hierarchy  */}
            <Button variant="text" color="primary">
              Member Of
            </Button>
            {user.kind === 'User' && (
              <UserMembershipHierarchyBreadcrumbs
                userEntity={user}
                orgGroupsOnly
              />
            )}
            {user.kind !== 'User' && (
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Member of">
                    <GroupIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>
                  <EntityRefLinks entityRefs={memberOfRelations} />
                </ListItemText>
              </ListItem>
            )}

            {/* Show If the person is from Engineering or Product */}
            <ListItem>
              {tags.map(t => (
                <Chip key={t} size="medium" label={t} color="primary" />
              ))}
            </ListItem>

            {props?.showLinks && <LinksGroup links={links} />}
          </List>
        </Grid>
      </Grid>
    </InfoCard>
  );
};
