import { useApi } from '@backstage/core-plugin-api';
import { techInsightsInfoApiRef } from '../../../techInsightsInfo';
import useAsync from 'react-use/lib/useAsync';

export const useGetNextRun = (factRetrieverId: string) => {
  const techInsightsInfoApi = useApi(techInsightsInfoApiRef);

  const { loading, error, value } = useAsync(async () => {
    return techInsightsInfoApi.getNextRun(factRetrieverId);
  }, [factRetrieverId]);

  return {
    loading,
    error,
    data: value,
  };
};
