import { EntityBadgesDialog } from '@backstage/plugin-badges';
import BadgeIcon from '@material-ui/icons/CallToAction';
import React, { ReactNode, useMemo, useState } from 'react';

import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CHILD_OF,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_MEMBER_OF,
  RELATION_PARENT_OF,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import { EmptyState } from '@backstage/core-components';
import { EntityAdrContent, isAdrAvailable } from '@backstage/plugin-adr';
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSubcomponentsCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  EntitySwitch,
  hasCatalogProcessingErrors,
  isComponentType,
  isKind,
  isOrphan,
  hasRelationWarnings,
  EntityRelationWarning,
} from '@backstage/plugin-catalog';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  EntityGithubActionsContent,
  isGithubActionsAvailable,
} from '@backstage/plugin-github-actions';
import { EntityTeamPullRequestsContent } from '@backstage/plugin-github-pull-requests-board';
import { EntityOwnershipCard } from '@backstage/plugin-org';
import { EntityPlaylistDialog } from '@backstage/plugin-playlist';
import { EntitySonarQubeCard } from '@backstage/plugin-sonarqube';
import { isSonarQubeAvailable } from '@backstage/plugin-sonarqube-react';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import {
  LightBox,
  ReportIssue,
  TextSize,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { NewAnnouncementBanner } from '@celonis/backstage-plugin-announcements';
import { Box, Button, Grid } from '@material-ui/core';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { Alert } from '@material-ui/lab';
import {
  EntityArgoCDOverviewCard,
  isArgocdAvailable,
} from '@roadiehq/backstage-plugin-argo-cd';
import { EntityGithubInsightsContent } from '@roadiehq/backstage-plugin-github-insights';
import {
  EntityGithubPullRequestsContent,
  isGithubPullRequestsAvailable,
} from '@roadiehq/backstage-plugin-github-pull-requests';
import { AboutCard as EntityAboutCard } from './AboutCard/AboutCard';
import { ArgoCdLink, ArgoCdImages } from './ArgoCdComponents';
import { EntityAudienceCard } from './AudienceCard/AudienceCard';
import { ClusterCard } from './ClusterCard/ClusterCard';
import { adrContentDecorator } from './adrContentDecorator';
import { ScoreCard } from '../techInsights/Scorecard';
import { GraphNode } from '../catalogGraph/CatalogGraphNode';
import { MembersListCard } from './MembersList/MemberListCard';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import { DomainRelatedEntitiesPage } from './DomainRelatedEntitiesPage';
import { UserProfileCard, GroupProfileCard } from '../org';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { getFlag } from '../../helpers/helpers';
import { ServiceAlignmentCard } from './ServiceAlignmentCard/ServiceAlignmentCard';
import { Config } from '@backstage/config';
import { EntityLinksCard } from '@celonis/backstage-plugin-catalog';

const isAnnouncementsEnabled = (configApi: Config): boolean => {
  return getFlag(configApi, 'announcements');
};

const EntityLayoutWrapper = (props: { children?: ReactNode }) => {
  const [badgesDialogOpen, setBadgesDialogOpen] = useState(false);
  const [playlistDialogOpen, setPlaylistDialogOpen] = useState(false);
  const configApi = useApi(configApiRef);

  const extraMenuItems = useMemo(() => {
    const result = [];
    if (getFlag(configApi, 'badges')) {
      result.push({
        title: 'Badges',
        Icon: BadgeIcon,
        onClick: () => setBadgesDialogOpen(true),
      });
    }
    if (getFlag(configApi, 'playlist')) {
      result.push({
        title: 'Add to playlist',
        Icon: PlaylistAddIcon,
        onClick: () => setPlaylistDialogOpen(true),
      });
    }
    return result;
  }, [configApi]);

  return (
    <>
      <EntityLayout
        UNSTABLE_extraContextMenuItems={extraMenuItems}
        UNSTABLE_contextMenuOptions={{
          disableUnregister: 'visible',
        }}
      >
        {props.children}
      </EntityLayout>
      <EntityBadgesDialog
        open={badgesDialogOpen}
        onClose={() => setBadgesDialogOpen(false)}
      />
      <EntityPlaylistDialog
        open={playlistDialogOpen}
        onClose={() => setPlaylistDialogOpen(false)}
      />
    </>
  );
};

const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
      <TextSize />
      <LightBox />
      <Mermaid
        darkConfig={{ theme: 'dark' }}
        lightConfig={{ theme: 'forest' }}
      />
    </TechDocsAddons>
  </EntityTechdocsContent>
);

const codeInsightsContent = (
  <EntityLayout.Route path="/code-insights" title="Code Insights">
    <EntityGithubInsightsContent />
  </EntityLayout.Route>
);

const cicdContent = (
  <EntitySwitch>
    <EntitySwitch.Case if={isGithubActionsAvailable}>
      <EntityGithubActionsContent view="cards" />
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const EntityWarningContent = () => {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <>
      <EntitySwitch>
        <EntitySwitch.Case if={hasRelationWarnings}>
          <Grid item xs={12}>
            <EntityRelationWarning />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>

      <EntitySwitch>
        <EntitySwitch.Case if={isOrphan}>
          <Grid item xs={12}>
            <EntityOrphanWarning />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>

      <EntitySwitch>
        <EntitySwitch.Case if={hasCatalogProcessingErrors}>
          <Grid item xs={12}>
            <Box>
              <Alert severity="error">
                <Box>There are errors while processing this entity.</Box>
                {isExpanded ? (
                  <Button
                    variant="text"
                    size="small"
                    color="secondary"
                    onClick={() => setExpanded(false)}
                  >
                    Hide errors
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    size="small"
                    color="secondary"
                    onClick={() => setExpanded(true)}
                  >
                    Show errors
                  </Button>
                )}
              </Alert>
            </Box>
            {isExpanded && <EntityProcessingErrorsPanel />}
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </>
  );
};

interface OverviewContentParams {
  announcementEnabled: boolean;
}

const overviewContent = ({ announcementEnabled }: OverviewContentParams) => {
  return (
    <Grid container spacing={3} alignItems="stretch">
      <EntityWarningContent />

      {announcementEnabled && (
        <Grid item md={12}>
          <NewAnnouncementBanner />
        </Grid>
      )}

      <Grid item md={4}>
        <EntityAboutCard variant="gridItem" />
      </Grid>

      <Grid item md={4} xs={12}>
        <EntityLinksCard variant="gridItem" />
      </Grid>

      <EntitySwitch>
        <EntitySwitch.Case
          if={isKind('component') && isComponentType('service')}
        >
          <Grid item md={4} xs={12}>
            <ServiceAlignmentCard variant="gridItem" />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>

      {/* second row */}
      <Grid item md={8} xs={12}>
        <EntityCatalogGraphCard
          variant="gridItem"
          height={400}
          renderNode={GraphNode}
          direction={Direction.LEFT_RIGHT}
          unidirectional
          maxDepth={1}
        />
      </Grid>

      <EntitySwitch>
        <EntitySwitch.Case if={entity => isSonarQubeAvailable(entity)}>
          <Grid item md={4}>
            <EntitySonarQubeCard variant="gridItem" />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>

      <Grid item md={12} xs={12}>
        <EntityHasSubcomponentsCard variant="gridItem" />
      </Grid>
    </Grid>
  );
};

const ArgoCdContent = () => {
  return (
    <Grid container spacing={3} alignItems="stretch">
      <Grid item md={12} xs={12}>
        <EntityArgoCDOverviewCard
          extraColumns={[
            {
              align: 'left',
              title: 'Environment',
              render: (data: any) => {
                return <>{data?.metadata?.labels?.env}</>;
              },
            },
            {
              align: 'left',
              title: 'Purpose',
              render: (data: any) => {
                return <>{data?.metadata?.labels?.purpose}</>;
              },
            },
            {
              align: 'left',
              title: 'Version',
              render: (data: any) => {
                return <ArgoCdImages images={data?.status?.summary?.images} />;
              },
            },
            {
              align: 'right',
              title: 'Go to Argo CD',
              render: (data: any) => {
                return <ArgoCdLink appName={data.metadata.name} />;
              },
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

const ServiceEntityPage = () => {
  const configApi = useApi(configApiRef);

  return (
    <EntityLayoutWrapper>
      <EntityLayout.Route path="/" title="Overview">
        {overviewContent({
          announcementEnabled: isAnnouncementsEnabled(configApi),
        })}
      </EntityLayout.Route>

      <EntityLayout.Route
        path="/scorecards"
        title="Scorecards"
        if={() => getFlag(configApi, 'tech-insights')}
      >
        <ScoreCard />
      </EntityLayout.Route>

      <EntityLayout.Route
        path="/adrs"
        title="ADRs"
        if={entity => isAdrAvailable(entity) && getFlag(configApi, 'adr')}
      >
        <EntityAdrContent contentDecorators={[adrContentDecorator()]} />
      </EntityLayout.Route>

      <EntityLayout.Route
        path="/argocd"
        title="Argo CD"
        if={entity => isArgocdAvailable(entity) && getFlag(configApi, 'argocd')}
      >
        <ArgoCdContent />
      </EntityLayout.Route>

      <EntityLayout.Route
        path="/pull-requests"
        title="Pull Requests"
        if={isGithubPullRequestsAvailable}
      >
        <EntityGithubPullRequestsContent />
      </EntityLayout.Route>

      <EntityLayout.Route path="/github-actions" title="Github Actions">
        {cicdContent}
      </EntityLayout.Route>

      {codeInsightsContent}

      <EntityLayout.Route path="/api" title="API">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={6}>
            <EntityProvidedApisCard />
          </Grid>
          <Grid item md={6}>
            <EntityConsumedApisCard />
          </Grid>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route path="/dependencies" title="Dependencies">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={6}>
            <EntityDependsOnComponentsCard variant="gridItem" />
          </Grid>
          <Grid item md={6}>
            <EntityDependsOnResourcesCard variant="gridItem" />
          </Grid>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route
        path="/docs"
        title="Docs"
        if={() => getFlag(configApi, 'techdocs')}
      >
        {techdocsContent}
      </EntityLayout.Route>
    </EntityLayoutWrapper>
  );
};

const LibraryEntityPage = () => {
  const configApi = useApi(configApiRef);

  return (
    <EntityLayoutWrapper>
      <EntityLayout.Route path="/" title="Overview">
        {overviewContent({
          announcementEnabled: isAnnouncementsEnabled(configApi),
        })}
      </EntityLayout.Route>

      <EntityLayout.Route
        path="/scorecards"
        title="Scorecards"
        if={() => getFlag(configApi, 'tech-insights')}
      >
        <ScoreCard />
      </EntityLayout.Route>

      <EntityLayout.Route
        path="/adrs"
        title="ADRs"
        if={entity => isAdrAvailable(entity) && getFlag(configApi, 'adr')}
      >
        <EntityAdrContent contentDecorators={[adrContentDecorator()]} />
      </EntityLayout.Route>
      <EntityLayout.Route
        path="/pull-requests"
        title="Pull Requests"
        if={isGithubPullRequestsAvailable}
      >
        <EntityGithubPullRequestsContent />
      </EntityLayout.Route>

      <EntityLayout.Route path="/github-actions" title="Github Actions">
        {cicdContent}
      </EntityLayout.Route>

      {codeInsightsContent}

      <EntityLayout.Route path="/dependencies" title="Dependencies">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={6}>
            <EntityDependsOnComponentsCard variant="gridItem" />
          </Grid>
          <Grid item md={6}>
            <EntityDependsOnResourcesCard variant="gridItem" />
          </Grid>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route
        path="/docs"
        title="Docs"
        if={() => getFlag(configApi, 'techdocs')}
      >
        {techdocsContent}
      </EntityLayout.Route>
    </EntityLayoutWrapper>
  );
};

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const DefaultEntityPage = () => {
  const configApi = useApi(configApiRef);

  return (
    /* Here we can set the default views to have on entities like databases and resources in general. To define  */
    <EntityLayoutWrapper>
      <EntityLayout.Route path="/" title="Overview">
        {overviewContent({
          announcementEnabled: isAnnouncementsEnabled(configApi),
        })}
      </EntityLayout.Route>
      <EntityLayout.Route path="/github-actions" title="Github Actions">
        {cicdContent}
      </EntityLayout.Route>

      {codeInsightsContent}

      <EntityLayout.Route
        if={entity => isAdrAvailable(entity) && getFlag(configApi, 'adr')}
        path="/adrs"
        title="ADRs"
      >
        <EntityAdrContent contentDecorators={[adrContentDecorator()]} />
      </EntityLayout.Route>

      <EntityLayout.Route
        path="/docs"
        title="Docs"
        if={() => getFlag(configApi, 'techdocs')}
      >
        {techdocsContent}
      </EntityLayout.Route>
    </EntityLayoutWrapper>
  );
};

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case>
      <ServiceEntityPage />
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType(['library'])}>
      <LibraryEntityPage />
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <DefaultEntityPage />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const ResourcePage = () => {
  const configApi = useApi(configApiRef);

  return (
    <EntityLayoutWrapper>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3}>
          <EntityWarningContent />
          <Grid item md={5}>
            <EntityAboutCard />
          </Grid>
          <Grid item md={7} xs={12}>
            <EntityCatalogGraphCard
              variant="gridItem"
              height={200}
              renderNode={GraphNode}
              direction={Direction.LEFT_RIGHT}
              unidirectional
              maxDepth={1}
            />
          </Grid>

          <Grid item md={5}>
            <EntityLinksCard variant="gridItem" />
          </Grid>
          <EntitySwitch>
            <EntitySwitch.Case if={e => e.spec?.type === 'cluster'}>
              <Grid item md={7}>
                <ClusterCard variant="gridItem" />
              </Grid>
            </EntitySwitch.Case>
          </EntitySwitch>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route
        if={entity => isAdrAvailable(entity) && getFlag(configApi, 'adr')}
        path="/adrs"
        title="ADRs"
      >
        <EntityAdrContent contentDecorators={[adrContentDecorator()]} />
      </EntityLayout.Route>

      <EntityLayout.Route
        path="/argocd"
        title="Argo CD"
        if={entity => isArgocdAvailable(entity) && getFlag(configApi, 'argocd')}
      >
        <ArgoCdContent />
      </EntityLayout.Route>

      <EntityLayout.Route
        path="/pull-requests"
        title="Pull Requests"
        if={isGithubPullRequestsAvailable}
      >
        <EntityGithubPullRequestsContent />
      </EntityLayout.Route>

      <EntityLayout.Route path="/github-actions" title="Github Actions">
        {cicdContent}
      </EntityLayout.Route>

      {codeInsightsContent}
      <EntityLayout.Route path="/dependencies" title="Dependencies">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={6}>
            <EntityDependsOnComponentsCard variant="gridItem" />
          </Grid>

          <Grid item md={6}>
            <EntityDependsOnResourcesCard variant="gridItem" />
          </Grid>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route
        path="/docs"
        title="Docs"
        if={() => getFlag(configApi, 'techdocs')}
      >
        {techdocsContent}
      </EntityLayout.Route>
    </EntityLayoutWrapper>
  );
};

const ApiPage = () => {
  const config = useApi(configApiRef);

  return (
    <EntityLayoutWrapper>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3}>
          <EntityWarningContent />
          <Grid item md={6}>
            <EntityAboutCard />
          </Grid>
          <Grid item md={6} xs={12}>
            <EntityCatalogGraphCard
              variant="gridItem"
              height={200}
              renderNode={GraphNode}
              direction={Direction.LEFT_RIGHT}
              unidirectional
              maxDepth={1}
            />
          </Grid>

          <Grid item md={6}>
            <EntityAudienceCard />
          </Grid>

          <Grid item md={6}>
            <EntityLinksCard variant="gridItem" />
          </Grid>

          <EntitySwitch>
            <EntitySwitch.Case
              if={e => isSonarQubeAvailable(e) && getFlag(config, 'sonarqube')}
            >
              <Grid item md={6}>
                <EntitySonarQubeCard variant="gridItem" />
              </Grid>
            </EntitySwitch.Case>
          </EntitySwitch>

          <Grid container item md={12}>
            <Grid item md={6}>
              <EntityProvidingComponentsCard />
            </Grid>
            <Grid item md={6}>
              <EntityConsumingComponentsCard />
            </Grid>
          </Grid>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route
        path="/scorecards"
        title="Scorecards"
        if={() => getFlag(config, 'tech-insights')}
      >
        <ScoreCard />
      </EntityLayout.Route>
      <EntityLayout.Route
        path="/adrs"
        title="ADRs"
        if={entity => isAdrAvailable(entity) && getFlag(config, 'adr')}
      >
        <EntityAdrContent contentDecorators={[adrContentDecorator()]} />
      </EntityLayout.Route>

      <EntityLayout.Route path="/github-actions" title="Github Actions">
        {cicdContent}
      </EntityLayout.Route>

      <EntityLayout.Route path="/definition" title="Definition">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <EntityApiDefinitionCard />
          </Grid>
        </Grid>
      </EntityLayout.Route>
    </EntityLayoutWrapper>
  );
};

const userPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={2}>
        <EntityWarningContent />
        <Grid item xs={12} md={6}>
          <UserProfileCard variant="gridItem" showLinks />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/org-diagram" title="Org Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.LEFT_RIGHT}
        title="Org Diagram"
        height={700}
        relations={[RELATION_CHILD_OF, RELATION_MEMBER_OF]}
        kinds={['Group', 'User']}
        unidirectional
        maxDepth={1}
        renderNode={GraphNode}
      />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const groupPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        <EntityWarningContent />
        <Grid item xs={12} md={6}>
          <GroupProfileCard variant="gridItem" showLinks />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        {/* <Grid item xs={12} md={12}>
          <EntityMembersListCard showAggregateMembersToggle />
        </Grid> */}
        <Grid item xs={12} md={12}>
          <MembersListCard showAggregateMembersToggle />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/org-diagram" title="Org Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.LEFT_RIGHT}
        title="Org Diagram"
        height={700}
        relations={[RELATION_CHILD_OF, RELATION_PARENT_OF]}
        kinds={['Group', 'User']}
        unidirectional
        maxDepth={1}
        renderNode={GraphNode}
      />
    </EntityLayout.Route>
    <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      <EntityTeamPullRequestsContent />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const systemPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        <EntityWarningContent />
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.LEFT_RIGHT}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional
        renderNode={GraphNode}
        maxDepth={1}
      />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const domainPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        <EntityWarningContent />
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.LEFT_RIGHT}
        title="Domain Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional
        renderNode={GraphNode}
        maxDepth={2}
      />
    </EntityLayout.Route>
    <EntityLayout.Route path="/entities" title="Entities">
      <DomainRelatedEntitiesPage />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

export const neverPage = (
  <EntityLayoutWrapper>
    {/** Add any conditionaly rendered components cards here */}
    <ScoreCard />
    <EntitySonarQubeCard variant="gridItem" />
    <EntityGithubPullRequestsContent />
    <EntityGithubInsightsContent />
    <EntityTeamPullRequestsContent />
    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent contentDecorators={[adrContentDecorator()]} />
    </EntityLayout.Route>
    <EntityArgoCDOverviewCard />
    <EntityTechdocsContent />
  </EntityLayoutWrapper>
);
const initActionsPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/github-actions" title="Github Actions">
      {cicdContent}
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

export const entityPage = (
  <EntitySwitch>
    {/* The following is a hack to initialize the github-actions component*/}
    <EntitySwitch.Case if={isKind('initAction')} children={initActionsPage} />
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('resource')}>
      <ResourcePage />
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('api')}>
      <ApiPage />
    </EntitySwitch.Case>
    <EntitySwitch.Case
      if={isKind([
        'group',
        'GithubTeam',
        'MsgraphGroup',
        'WorkdayGroup',
        'ExtendedGroup',
      ])}
      children={groupPage}
    />
    <EntitySwitch.Case
      if={isKind([
        'user',
        'GithubUser',
        'MsgraphUser',
        'WorkdayUser',
        'ExtendedUser',
      ])}
      children={userPage}
    />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />
    {/* The following is a hack to show components conditionally. It forces initialise the API refs*/}
    <EntitySwitch.Case if={() => false} children={neverPage} />{' '}
    <EntitySwitch.Case>
      <DefaultEntityPage />
    </EntitySwitch.Case>
  </EntitySwitch>
);
