import { CompoundEntityRef } from '@backstage/catalog-model';
import { GroupIcon, Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import { entityRouteRef } from '@backstage/plugin-catalog-react';
import { IconButton, Tooltip } from '@material-ui/core';
import { uniqueId } from 'lodash';
import React from 'react';

type OwnerRefLinkProps = {
  compoundEntity: CompoundEntityRef;
};

export const OwnerRefLink = (props: OwnerRefLinkProps) => {
  const { compoundEntity: owner } = props;
  const entityRoute = useRouteRef(entityRouteRef);
  const routeParams = {
    kind: encodeURIComponent(owner.kind),
    namespace: encodeURIComponent(owner.namespace),
    name: encodeURIComponent(owner.name),
  };

  const tooltipTitle = `Maintained by ${owner.name}`;
  return (
    <Tooltip key={uniqueId(owner.name)} title={tooltipTitle}>
      <Link to={entityRoute(routeParams)}>
        <IconButton size="medium" color="primary">
          <GroupIcon fontSize="default" />
        </IconButton>
      </Link>
    </Tooltip>
  );
};
