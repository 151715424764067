import { datadogRum } from '@datadog/browser-rum';
import {
  useApi,
  configApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { isNil } from 'lodash';
import { useEffect } from 'react';

export const useDatadogRum = () => {
  const config = useApi(configApiRef);
  const identityApi = useApi(identityApiRef);

  const applicationId = config.getOptionalString(
    'app.datadogRum.applicationId',
  );
  const clientToken = config.getOptionalString('app.datadogRum.clientToken');
  const env = config.getOptionalString('app.datadogRum.env');

  useEffect(() => {
    if (isNil(applicationId) || isNil(clientToken)) {
      console.warn('No Datadog RUM configuration found.'); // eslint-disable-line no-console
      return;
    }

    datadogRum.init({
      applicationId,
      clientToken,
      env,
      site: 'datadoghq.com',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
    });

    identityApi.getProfileInfo().then(profile => {
      datadogRum.setUser({
        id: profile.email,
        email: profile.email,
        name: profile.displayName,
      });
    });
  }, [applicationId, clientToken, env, identityApi]);
};
