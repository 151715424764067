import { Entity } from '@backstage/catalog-model';
import { useEntityList } from '@backstage/plugin-catalog-react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { CustomFilters, EntityAudienceFilter } from '../../../catalog/filters';
import { get, isEmpty } from 'lodash';

export type CatalogReactEntityAudiencePickerClassKey = 'input';
const useStyles = makeStyles(
  {
    input: {},
  },
  {
    name: 'CatalogReactEntityLifecyclePicker',
  },
);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const EntityAudiencePicker = () => {
  const classes = useStyles();
  // The audience key is recognized due to the CustomFilter generic
  const {
    backendEntities,
    filters,
    updateFilters,
    queryParameters: { audiences: audiencesParameter },
  } = useEntityList<CustomFilters>();

  const queryParamAudiences = useMemo(
    () => [audiencesParameter].flat().filter(Boolean) as string[],
    [audiencesParameter],
  );

  const [selectedAudiences, setSelectedAudiences] = useState(
    isEmpty(queryParamAudiences)
      ? get(filters, ['audiences', 'values'], [])
      : queryParamAudiences,
  );
  // Set selected owners on query parameter updates; this happens at initial page load and from
  // external updates to the page location.
  useEffect(() => {
    if (queryParamAudiences.length) {
      setSelectedAudiences(queryParamAudiences);
    }
  }, [queryParamAudiences]);

  // Get unique values from the selected kind
  const availableAudiences = useMemo(
    () =>
      [
        ...new Set(
          backendEntities
            .map((e: Entity) => e.spec?.audience)
            .filter(Boolean) as string[],
        ),
      ].sort((a, b) => a.localeCompare(b)),
    [backendEntities],
  );

  useEffect(() => {
    updateFilters({
      audiences:
        selectedAudiences.length && availableAudiences.length
          ? new EntityAudienceFilter(selectedAudiences)
          : undefined,
    });
  }, [selectedAudiences, updateFilters, availableAudiences]);

  // Don't show "filter audience" if there are no values
  if (!availableAudiences.length) return null;

  return (
    <Box pb={1} pt={1}>
      <Typography variant="button" component="label">
        Audience
        <Autocomplete
          multiple
          disableCloseOnSelect
          options={availableAudiences}
          value={selectedAudiences}
          onChange={(_: object, value: string[]) => setSelectedAudiences(value)}
          renderOption={(option, { selected }) => (
            <FormControlLabel
              control={
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                />
              }
              label={option}
            />
          )}
          size="small"
          popupIcon={<ExpandMoreIcon data-testid="audience-picker-expand" />}
          renderInput={params => (
            <TextField
              {...params}
              className={classes.input}
              variant="outlined"
            />
          )}
        />
      </Typography>
    </Box>
  );
};
