import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function colorRules(threshold: number): string {
  if (threshold < 35) {
    return '#E92308';
  } else if (threshold < 70) {
    return 'orange';
  }
  return '#3FBD39';
}

export function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  const progress = props.value;

  const colorRule = colorRules(progress);

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <div style={{ color: colorRule }}>
        <CircularProgress
          variant="determinate"
          {...props}
          size={90}
          color="inherit"
        />
      </div>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h5" component="div">{`${Math.round(
          progress,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
