import React from 'react';
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpOutline from '@material-ui/icons/HelpOutline';

interface FilterBoxProps {
  availableData: string[];
  selectedData: any;
  setSelectedData: any;
  title: string;
  tooltipDescription: string;
}

const useStyles = makeStyles(
  {
    input: {},
  },
  {
    name: 'CatalogReactPicker',
  },
);

export const AutocompleteFilter = (props: FilterBoxProps) => {
  const classes = useStyles();
  const {
    availableData,
    selectedData,
    setSelectedData,
    title,
    tooltipDescription,
  } = props;
  return (
    <Box pb={1} pt={1}>
      <Tooltip title={tooltipDescription} placement="right-start" arrow>
        <Typography variant="button" component="label">
          {title}
          {'  '}
          <HelpOutline style={{ fontSize: '1rem' }} />
        </Typography>
      </Tooltip>
      <Autocomplete
        multiple
        disableCloseOnSelect
        options={availableData}
        value={selectedData}
        onChange={(_: object, value: string[]) => setSelectedData(value)}
        renderOption={(option, { selected }) => (
          <FormControlLabel
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                checked={selected}
              />
            }
            label={option}
          />
        )}
        size="small"
        popupIcon={<ExpandMoreIcon data-testid="system-picker-expand" />}
        renderInput={params => (
          <TextField {...params} className={classes.input} variant="outlined" />
        )}
      />
    </Box>
  );
};
