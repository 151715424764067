import { Entity } from '@backstage/catalog-model';
import { Typography } from '@material-ui/core';
import { EntityTable } from '@backstage/plugin-catalog-react';
import React from 'react';
import {
  InfoCard,
  InfoCardVariants,
  Link,
  Progress,
  ResponseErrorPanel,
  TableColumn,
} from '@backstage/core-components';
import { useRelatedEntitiesForMultipleEntities } from './useRelatedEntitiesForMultipleEntities';

/** @public */
export type RelatedEntitiesCardProps<T extends Entity> = {
  variant?: InfoCardVariants;
  title: string;
  columns: TableColumn<T>[];
  entityKind?: string;
  relationType: string;
  emptyMessage: string;
  emptyHelpLink: string;
  asRenderableEntities: (entities: Entity[]) => T[];
  entities?: Entity[];
};

/**
 * A low level card component that can be used as a building block for more
 * specific cards.
 *
 * @remarks
 *
 * You probably want to make a dedicated component for your needs, which renders
 * this card as its implementation with some of the props set to the appropriate
 * values.
 *
 * @public
 */
export function RelatedEntitiesCard<T extends Entity>(
  props: RelatedEntitiesCardProps<T>,
) {
  const {
    variant = 'gridItem',
    title,
    columns,
    entityKind,
    relationType,
    emptyMessage,
    emptyHelpLink,
    asRenderableEntities,
    entities = [],
  } = props;

  const { relatedEntities, loading, error } =
    useRelatedEntitiesForMultipleEntities(entities, {
      type: relationType,
      kind: entityKind,
    });

  if (loading) {
    return (
      <InfoCard variant={variant} title={title}>
        <Progress />
      </InfoCard>
    );
  }

  if (error) {
    return (
      <InfoCard variant={variant} title={title}>
        <ResponseErrorPanel error={error} />
      </InfoCard>
    );
  }

  return (
    <EntityTable
      title={title}
      variant={variant}
      emptyContent={
        <div style={{ textAlign: 'center' }}>
          <Typography variant="body1">{emptyMessage}</Typography>
          <Typography variant="body2">
            <Link to={emptyHelpLink}>Learn how to change this.</Link>
          </Typography>
        </div>
      }
      columns={columns}
      entities={asRenderableEntities(relatedEntities || [])}
    />
  );
}
