import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { Icon } from '@iconify/react';
import React from 'react';

export const CelonisIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="34.378"
    height="36.122"
    viewBox="0 0 34.378 36.122"
  >
    <g>
      <path
        fill="#000"
        d="m28.25,3.502c-2.286-2.291-4.875-3.502-7.488-3.502-2.907,0-3.908,1.525-5.066,3.291-1.009,1.538-2.265,3.452-5.212,5.529-1.351.952-2.653,1.715-3.802,2.389-1.922,1.127-3.581,2.1-4.773,3.503-1.303,1.535-1.91,3.463-1.91,6.066,0,4.223,1.723,8.104,4.853,10.928,3.136,2.83,7.487,4.399,12.254,4.416h.159c4.662-.02,9.001-1.854,12.217-5.164,3.146-3.237,4.885-7.505,4.896-12.016.014-5.634-2.392-11.695-6.128-15.44Zm-11.141,30.947c-4.351-.016-8.306-1.432-11.136-3.986-2.773-2.503-4.301-5.943-4.301-9.686,0-2.216.473-3.759,1.533-5.003,1.002-1.176,2.468-2.035,4.324-3.123,1.177-.69,2.512-1.472,3.92-2.464,3.208-2.26,4.617-4.409,5.647-5.978,1.142-1.741,1.663-2.535,3.667-2.535,2.159,0,4.338,1.041,6.303,3.011,3.385,3.394,5.652,9.122,5.639,14.255-.01,4.075-1.581,7.93-4.423,10.854-2.9,2.985-6.815,4.639-11.021,4.657h-.152Z"
      />
      <path
        fill="#000"
        d="m18.037,15.598c.809,0,1.482.206,2.02.617.537.412.875.98,1.015,1.706h2.532c-.237-1.353-.844-2.424-1.82-3.212-.977-.788-2.212-1.182-3.704-1.182-1.117,0-2.103.237-2.962.711-.858.474-1.532,1.158-2.02,2.051-.489.893-.733,1.939-.733,3.139,0,1.214.244,2.267.732,3.16.488.893,1.161,1.577,2.02,2.051.858.474,1.845.712,2.961.712,1.493,0,2.728-.398,3.704-1.193.976-.795,1.584-1.869,1.821-3.223h-2.532c-.14.726-.478,1.297-1.015,1.716-.537.419-1.211.628-2.02.628-.921,0-1.688-.328-2.302-.984-.614-.656-.921-1.611-.921-2.867,0-1.256.307-2.208.921-2.857.614-.649,1.381-.973,2.302-.973Z"
      />
    </g>
  </SvgIcon>
);

export const SubtribeIcon = (props: SvgIconProps) => {
  const iconPath = (
    <path
      fill="currentColor"
      width="1920"
      height="1792"
      display="inline-block"
      d="M593 896q-162 5-265 128H194q-82 0-138-40.5T0 865q0-353 124-353q6 0 43.5 21t97.5 42.5T384 597q67 0 133-23q-5 37-5 66q0 139 81 256zm1071 637q0 120-73 189.5t-194 69.5H523q-121 0-194-69.5T256 1533q0-53 3.5-103.5t14-109T300 1212t43-97.5t62-81t85.5-53.5T602 960q10 0 43 21.5t73 48t107 48t135 21.5t135-21.5t107-48t73-48t43-21.5q61 0 111.5 20t85.5 53.5t62 81t43 97.5t26.5 108.5t14 109t3.5 103.5zM640 256q0 106-75 181t-181 75t-181-75t-75-181t75-181T384 0t181 75t75 181zm704 384q0 159-112.5 271.5T960 1024T688.5 911.5T576 640t112.5-271.5T960 256t271.5 112.5T1344 640zm576 225q0 78-56 118.5t-138 40.5h-134q-103-123-265-128q81-117 81-256q0-29-5-66q66 23 133 23q59 0 119-21.5t97.5-42.5t43.5-21q124 0 124 353zm-128-609q0 106-75 181t-181 75t-181-75t-75-181t75-181t181-75t181 75t75 181z"
    />
  );

  return (
    <SvgIcon {...props} viewBox="0 0 1920 1792">
      {iconPath}
    </SvgIcon>
  );
};

export const TribeIcon = (props: SvgIconProps) => {
  const iconPath = (
    <g xmlns="http://www.w3.org/2000/svg" width="48" height="48">
      <mask id="ipSCirclesSeven0">
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          width="48"
          height="48"
          d="M24 12a4 4 0 1 0 0-8a4 4 0 0 0 0 8Zm0 16a4 4 0 1 0 0-8a4 4 0 0 0 0 8Zm10.392-10a4 4 0 1 0 6.929-4a4 4 0 0 0-6.928 4Zm0 12a4 4 0 1 0 6.929 4a4 4 0 0 0-6.928-4ZM24 36a4 4 0 1 0 0 8a4 4 0 0 0 0-8Zm-10.392-6a4 4 0 1 0-6.928 4a4 4 0 0 0 6.928-4Zm0-12a4 4 0 1 0-6.928-4a4 4 0 0 0 6.928 4Z"
          clipRule="evenodd"
        />
      </mask>
      <path
        fill="currentColor"
        d="M0 0h48v48H0z"
        mask="url(#ipSCirclesSeven0)"
      />
    </g>
  );
  return (
    <SvgIcon {...props} viewBox="0 0 48 48">
      {iconPath}
    </SvgIcon>
  );
};

export const OrgIcon = (props: SvgIconProps) => {
  const iconPath = (
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      width="24"
      height="24"
      display="inline-block"
      d="M20 3v20H4V3h16ZM8.042 9h2V7h-2v2ZM14 9h2V7h-2v2Zm-5.958 6h2v-2h-2v2Zm2 8h4v-4h-4v4ZM14 15h2v-2h-2v2ZM2 3h20V1H2v2Z"
    />
  );

  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      {iconPath}
    </SvgIcon>
  );
};

export const PillarIcon = (props: SvgIconProps) => {
  const iconPath = (
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="2.75" cy="2.75" r="2.25" />
      <circle cx="11.25" cy="2.75" r="2.25" />
      <path d="M3 .5h8m-6.84 4h5.68M3 13.5V4.99m2.5 8.51v-6m3 6v-6m2.5 6V4.99" />
    </g>
  );

  return (
    <SvgIcon {...props} viewBox="0 0 14 14">
      {iconPath}
    </SvgIcon>
  );
};
export const InternalGroupIcon = (props: SvgIconProps) => {
  const iconPath = (
    <g width="24" height="24">
      <circle cx="10" cy="8" r="4" fill="currentColor" />
      <path
        fill="currentColor"
        d="M10.67 13.02c-.22-.01-.44-.02-.67-.02c-2.42 0-4.68.67-6.61 1.82c-.88.52-1.39 1.5-1.39 2.53V20h9.26a6.963 6.963 0 0 1-.59-6.98zM20.75 16c0-.22-.03-.42-.06-.63l1.14-1.01l-1-1.73l-1.45.49c-.32-.27-.68-.48-1.08-.63L18 11h-2l-.3 1.49c-.4.15-.76.36-1.08.63l-1.45-.49l-1 1.73l1.14 1.01c-.03.21-.06.41-.06.63s.03.42.06.63l-1.14 1.01l1 1.73l1.45-.49c.32.27.68.48 1.08.63L16 21h2l.3-1.49c.4-.15.76-.36 1.08-.63l1.45.49l1-1.73l-1.14-1.01c.03-.21.06-.41.06-.63zM17 18c-1.1 0-2-.9-2-2s.9-2 2-2s2 .9 2 2s-.9 2-2 2z"
      />
    </g>
  );

  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      {iconPath}
    </SvgIcon>
  );
};
export type CustomIconProps = {
  icon: string;
  className?: string;
};

export const CustomIcon = (props: CustomIconProps) => (
  <Icon icon={props.icon} className={props.className} />
);

export type RelationIconProps = {
  icon?: string;
  className?: string;
};

export const RelationIcon = (props: RelationIconProps) => (
  <Icon icon="fluent-mdl2:relationship" className={props.className} />
);
export const ArrowUpIcon = (props: RelationIconProps) => (
  <Icon icon="mdi:arrow-up-bold" className={props.className} />
);
export const ArrowDownIcon = (props: RelationIconProps) => (
  <Icon icon="mdi:arrow-down-bold" className={props.className} />
);

export const ADRIcon = ({ width }: { width: number }) => (
  <Icon icon="fluent-mdl2:decision-solid" width={width} />
);
