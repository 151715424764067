import React from 'react';
import type { FC } from 'react';
import type { CheckProps, CustomCheckResult } from './types';
import { CheckResultRenderer } from './types';
import { RenderDescription } from './TemplatingEngine';
import * as nunjucks from 'nunjucks';
import { StatusError, StatusOK } from '@backstage/core-components';

const EVENT_TYPE = 'boolean';

nunjucks.configure({
  autoescape: true,
});

export const BooleanCheckResultRenderer: FC<CheckProps> = ({ checkResult }) => {
  if (checkResult.result === true) {
    return <StatusOK />;
  }
  return <StatusError />;
};

export const renderer: CheckResultRenderer = {
  type: EVENT_TYPE,
  component: checkResult => (
    <BooleanCheckResultRenderer checkResult={checkResult} />
  ),
  description: checkResult => (
    <RenderDescription checkResult={checkResult as CustomCheckResult} />
  ),
};
