import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  identityApiRef,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import {
  TechInsightsClient,
} from './techInsights/TechInsightsClient';
import { techInsightsApiRef } from './techInsights/TechInsightsApi';
import { eventBasedRenderer } from './components/techInsights/eventBasedRenderer';
import {
  TechInsightsGroupsHttpApi,
  techinsightsGroupsApiRef,
} from './techInsights-groups';
import {
  techInsightsInfoApiRef,
  TechInsightsInfoHttpApi,
} from './techInsightsInfo';
import { DefaultGroupHierarchyApi, groupHierarchyApiRef } from '@celonis/plugin-group-hierarchy';
import { FrontendHostDiscovery } from '@backstage/core-app-api';
import { ResultsClient } from './tech-radar/client';
import { techRadarApiRef } from '@backstage/plugin-tech-radar';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: discoveryApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => FrontendHostDiscovery.fromConfig(configApi),
  }),

  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: techInsightsApiRef,
    deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
    factory: ({ discoveryApi, identityApi }) =>
      new TechInsightsClient({
        discoveryApi,
        identityApi,
        // @ts-ignore
        renderers: [eventBasedRenderer],
      }),
  }),
  createApiFactory({
    api: techinsightsGroupsApiRef,
    deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
    factory: ({ discoveryApi, fetchApi }) =>
      TechInsightsGroupsHttpApi.create(discoveryApi, fetchApi),
  }),
  createApiFactory({
    api: techInsightsInfoApiRef,
    deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
    factory: ({ discoveryApi, fetchApi }) =>
      TechInsightsInfoHttpApi.create(discoveryApi, fetchApi),
  }),

  createApiFactory({
    api: groupHierarchyApiRef,
    deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
    factory: ({ discoveryApi, fetchApi }) =>
      DefaultGroupHierarchyApi.create(discoveryApi, fetchApi),
  }),

  createApiFactory( {
    api:techRadarApiRef,
    deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
    factory: ({ discoveryApi, identityApi }) =>
      new ResultsClient({discoveryApi, identityApi})
  })
];
