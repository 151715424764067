import React from 'react';
import { useOutlet } from 'react-router-dom';
import {
  DefaultCatalogPage,
  DefaultCatalogPageProps,
} from './DefaultCatalogPage';

/**
 *
 * @public
 */
export const AdminExplorerPage = (props: DefaultCatalogPageProps) => {
  const outlet = useOutlet();
  return outlet || <DefaultCatalogPage {...props} />;
};

/**
 *
 * @public
 */
export const APIExplorerPage = (props: DefaultCatalogPageProps) => {
  const outlet = useOutlet();
  return outlet || <DefaultCatalogPage {...props} />;
};

/**
 *
 * @public
 */
export function CatalogPageAPI(props: Readonly<DefaultCatalogPageProps>) {
  return <DefaultCatalogPage {...props} />;
}

/**
 *
 * @public
 */
export function CatalogPage(props: Readonly<DefaultCatalogPageProps>) {
  return <DefaultCatalogPage {...props} />;
}
