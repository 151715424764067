import { BackstageTheme } from '@backstage/theme';
import { Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { CodeSnippet } from '@backstage/core-components';

const ENTITY_YAML = `spec:
  type: openapi
  audience: x`;

/** @public */
export type EntityAudienceEmptyStateClassKey = 'code';

const useStyles = makeStyles<BackstageTheme>(
  theme => ({
    code: {
      borderRadius: 6,
      margin: `${theme.spacing(2)}px 0px`,
      background:
        theme.palette.type === 'dark' ? '#444' : theme.palette.common.white,
    },
  }),
  { name: 'PluginCatalogEntityLinksEmptyState' },
);

export function EntityAudienceEmptyState() {
  const classes = useStyles();

  return (
    <>
      <Typography variant="body1">
        Missing audience field! Add it to your entity YAML
        as shown in the highlighted example below:
      </Typography>
      <div className={classes.code}>
        <CodeSnippet
          text={ENTITY_YAML}
          language="yaml"
          showLineNumbers
          highlightedNumbers={[3, 4, 5, 6]}
          customStyle={{ background: 'inherit', fontSize: '115%' }}
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        target="_blank"
        href="https://www.celonis.dev/catalog/default/component/global-api-policy/docs/#apis-audience"
      >
        Read more
      </Button>
    </>
  );
}