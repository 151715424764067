import {
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';

import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';

export class ResultsClient implements TechRadarApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async load(): Promise<TechRadarLoaderResponse> {
    const url = await this.discoveryApi.getBaseUrl('tech-radar');
    const { token } = await this.identityApi.getCredentials();

    const headers: HeadersInit = new Headers();
    if (!headers.has('content-type'))
      headers.set('content-type', 'application/json');
    if (token && !headers.has('authorization')) {
      headers.set('authorization', `Bearer ${token}`);
    }

    const request = new Request(`${url}/results`, {
      headers,
    });

    const data = (await fetch(request).then(res => {
      if (!res.ok) {
        throw ResponseError.fromResponse(res);
      }
      return res.json();
    })) as TechRadarLoaderResponse;
  
    return {
      ...data,
      entries: data.entries.map(entry => ({
        ...entry,
        timeline: entry.timeline.map(timeline => ({
          ...timeline,
          date: new Date(timeline.date),
        })),
      })),
    };
  }
}
