import { InfoCard, InfoCardVariants, Table } from '@backstage/core-components';
import { Chip, Grid } from '@material-ui/core';
import { AboutField } from '@backstage/plugin-catalog';
import { startCase } from 'lodash';
import { useEntity } from '@backstage/plugin-catalog-react';
import React from 'react';

export interface ClusterCardProps {
  variant?: InfoCardVariants;
}

interface ClusterContentProps {
  title: string;
  value: any;
}

const ipColumns = [
  {
    title: 'IP',
    field: 'ip',
    hidden: false,
    width: 'auto',
  },
  {
    title: 'Port Number',
    field: 'ports.number',
    hidden: false,
    width: 'auto',
  },
  {
    title: 'IP Traffic',
    field: 'traffic',
    hidden: false,
    width: 'auto',
  },
  {
    title: 'Description',
    field: 'ports.description',
    hidden: false,
    width: 'auto',
  },
  // TODO: Data not available now, might be in the future. So add hidden=false if needed
  {
    title: 'Type',
    field: 'type',
    hidden: true,
    width: 'auto',
  },
  {
    title: 'Protocol',
    field: 'ports.protocol',
    hidden: true,
    width: 'auto',
  },
];

function fillData(
  data: Record<string, any>[],
  networkType: string,
  indexOffset: number,
): Record<string, any>[] {
  const color = networkType === 'ingress' ? 'primary' : 'default';

  return data.flatMap((ipData, index: number) => {
    const ipIndex = index + indexOffset;

    if (ipData.ports) {
      return ipData.ports.map(
        (port: Record<string, any>, portIndex: number) => {
          const rowData: Record<string, any> = {};
          rowData.id = `${ipIndex}-${portIndex}`;
          rowData.ip = (
            <Chip
              key={ipData.ip}
              size="small"
              label={ipData.ip}
              color={color}
            />
          );
          rowData.traffic = networkType;
          rowData.type = ipData.type;
          rowData.ports = port;
          return rowData;
        },
      );
    }

    return {
      id: ipIndex,
      ip: <Chip key={ipData.ip} size="small" label={ipData.ip} color={color} />,
      traffic: networkType,
      type: ipData.type,
    };
  });
}

const ClusterContent: React.FC<ClusterContentProps> = props => {
  const title = startCase(props.title);

  if (Array.isArray(props.value)) {
    if (title === 'Ingress Ips' || title === 'Egress Ips') return null;
    return (
      <AboutField label={title} gridSizes={{ xs: 12, sm: 6, lg: 4 }}>
        {props.value.map(t => (
          <Chip key={t} size="small" label={t} />
        ))}
      </AboutField>
    );
  }

  return (
    <AboutField
      label={title}
      value={props.value}
      gridSizes={{ xs: 12, sm: 6, lg: 4 }}
    />
  );
};

export function ClusterCard(props: ClusterCardProps) {
  const { variant } = props;
  const { entity } = useEntity();

  const custom = entity.spec!.custom ?? ({} as any);
  // @ts-ignore
  const ingress = entity.spec!.custom!.ingressIps ?? [];
  // @ts-ignore
  const egress = entity.spec!.custom!.egressIps ?? [];

  const ipRows = [
    ...fillData(ingress, 'ingress', 0),
    ...fillData(egress, 'egress', ingress.length),
  ];

  return (
    <InfoCard title="Cluster Data" variant={variant}>
      <Grid container>
        {Object.entries(custom).map(([key, value]) => (
          <ClusterContent title={key} value={value} />
        ))}
      </Grid>
      <Table title="IP Table" data={ipRows} columns={ipColumns} />
    </InfoCard>
  );
}
