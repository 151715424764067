import React from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { entityLinkTypes } from '@celonis/backstage-plugin-celodrome-common';
import { SelectChangeEvent } from '@mui/material/Select';

export const EntityLinkTypePicker = (
  props: FieldExtensionComponentProps<string>,
) => {
  const {
    onChange,
    schema: { title = 'Link Type', description = 'Type of the link' },
    formData,
  } = props;

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as string);
  };

  const types = Object.values(entityLinkTypes);

  const selectedType =
    types.find(t => t === formData) ?? formData ? formData : '';

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{title}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedType}
        label={title}
        // @ts-ignore
        onChange={handleChange}
      >
        {types.map((type, index) => (
          <MenuItem key={`${type}-${index}`} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{description}</FormHelperText>
    </FormControl>
  );
};
