import { Entity } from '@backstage/catalog-model';
import { useEntityList } from '@backstage/plugin-catalog-react';
import React, { useEffect, useMemo, useState } from 'react';
import { CustomFilters, EntitySystemFilter } from '../../../catalog/filters';
import { get, isEmpty } from 'lodash';
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export type CatalogReactEntityAudiencePickerClassKey = 'input';

export interface EntitySystemPickerProps {
  hidden?: boolean;
}

export const EntitySystemPicker = (props: EntitySystemPickerProps) => {
  const { hidden } = props;

  // The system key is recognized due to the CustomFilter generic
  const {
    backendEntities,
    filters,
    updateFilters,
    queryParameters: { systems: systemsParameter },
  } = useEntityList<CustomFilters>();

  const queryParamSystems = useMemo(
    () => [systemsParameter].flat().filter(Boolean) as string[],
    [systemsParameter],
  );

  const [selectedSystems, setSelectedSystems] = useState(
    isEmpty(queryParamSystems)
      ? get(filters, ['systems', 'values'], [])
      : queryParamSystems,
  );
  // Set selected owners on query parameter updates; this happens at initial page load and from
  // external updates to the page location.
  useEffect(() => {
    if (queryParamSystems.length) {
      setSelectedSystems(queryParamSystems);
    }
  }, [queryParamSystems]);

  const availableSystems = useMemo(
    () =>
      [
        ...new Set(
          backendEntities
            .map((e: Entity) => e.spec?.system)
            .filter(Boolean) as string[],
        ),
      ].sort((a, b) => a.localeCompare(b)),
    [backendEntities],
  );

  useEffect(() => {
    updateFilters({
      systems:
        selectedSystems.length && availableSystems.length
          ? new EntitySystemFilter(selectedSystems)
          : undefined,
    });
  }, [selectedSystems, updateFilters, availableSystems]);

  // Don't show "filter system" if there are no values
  if (!availableSystems.length) return null;
  return hidden ? null : (
    <Box pb={1} pt={1}>
      <Typography variant="button" component="label">
        System
      </Typography>
      <Autocomplete
        multiple
        disableCloseOnSelect
        options={availableSystems}
        value={selectedSystems}
        onChange={(_: object, value: string[]) => setSelectedSystems(value)}
        renderOption={(option, { selected }) => (
          <FormControlLabel
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                checked={selected}
              />
            }
            label={option}
          />
        )}
        size="small"
        popupIcon={<ExpandMoreIcon data-testid="system-picker-expand" />}
        renderInput={params => <TextField {...params} variant="outlined" />}
      />
    </Box>
  );
};
