import { FieldValidation } from '@rjsf/utils';
import { KubernetesValidatorFunctions } from '@backstage/catalog-model';
import { ApiHolder } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { FieldExtensionUiSchema } from '@backstage/plugin-scaffolder-react';
import { JsonObject } from '@backstage/types';
import { isNil, merge } from 'lodash';
import { EntityFilterQuery } from '@backstage/catalog-client';

/*
 This is a validation function that will run when the form is submitted.
  You will get the value from the `onChange` handler before as the value here to make sure that the types are aligned\
*/
export const uniqueEntityNameValidation = async (
  value: string,
  validation: FieldValidation,
  context: {
    apiHolder: ApiHolder;
    formData?: JsonObject;
    schema?: JsonObject;
    uiSchema?: FieldExtensionUiSchema<string, {}> | undefined;
  },
) => {
  const { apiHolder, uiSchema } = context;
  const catalogApi = apiHolder.get(catalogApiRef);

  const query = buildQuery(value, uiSchema);

  const { items } = await catalogApi!.getEntities({
    fields: ['metadata.name'],
    filter: query,
  });

  // Check entity exists
  if (items.length > 0) {
    validation.addError('Entity already exists');
  }

  // Check format
  if (!KubernetesValidatorFunctions.isValidObjectName(value)) {
    validation.addError(
      'Must start and end with an alphanumeric character, and contain only alphanumeric characters, hyphens, underscores, and periods. Maximum length is 63 characters.',
    );
  }
};

// We just want to keep "kind" filters, and ignore any other values
export function processCatalogFilter(
  catalogFilter: Array<object>,
): EntityFilterQuery {
  const query: Record<string, any> = {};
  for (const item of catalogFilter) {
    if ('kind' in item) {
      const value = item.kind;

      if (Array.isArray(value)) {
        query.kind = (query.kind || []).concat(...value);
      } else {
        query.kind = (query.kind || []).concat(value);
      }
    }
  }
  return query;
}

export function buildQuery(
  value: string,
  uiSchema?: FieldExtensionUiSchema<string, {}>,
): EntityFilterQuery {
  const basicQuery = {
    'metadata.name': value,
  };
  if (isNil(uiSchema) || isNil(uiSchema['ui:options'])) return basicQuery;

  const catalogFilter =
    (uiSchema['ui:options'].catalogFilter as Array<object>) ?? [];

  const query = processCatalogFilter(catalogFilter);

  return merge({}, basicQuery, query);
}
