import React from 'react';
import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { get, isNumber } from 'lodash';

import type { FC } from 'react';
import type { CheckResult, CustomCheckResult, Event } from './types';
import { CheckResultRenderer } from './types';
import { RenderDescription } from './TemplatingEngine';
import { scorecardPalette as palette } from './ScorecardTheme';

const EVENT_TYPE = 'percentage';

export interface PercentageProps {
  checkResult: CheckResult<
    Event<
      typeof EVENT_TYPE,
      {
        total: number;
        factId: string;
        result: 'SUCCESS' | 'WARN' | 'ERROR';
        showPercentage?: boolean;
      }
    >
  >;
}

interface PercentageStylingProps {
  result: string;
  showPercentage: boolean;
}

const usePercentageStyling = makeStyles(theme => ({
  circleIndeterminate: {
    color: (props: PercentageStylingProps) => {
      const { result } = props;

      if (result === 'SUCCESS') {
        return palette.success.main;
      }

      if (result === 'WARN') {
        return palette.warning.main;
      }

      return palette.error.main;
    },
  },
  label: (props: PercentageStylingProps) => {
    if (props.showPercentage) {
      return {};
    }

    return {
      padding: '2px',
      background: 'rgba(255,255,255,0.8)',
      borderRadius: theme.shape.borderRadius,
    };
  },
}));

export const Percentage: FC<PercentageProps> = ({ checkResult }) => {
  const {
    total,
    factId,
    result,
    showPercentage = true,
  } = checkResult.event.params;
  const current = get(checkResult, ['facts', factId, 'value']);

  const styles = usePercentageStyling({ result, showPercentage });

  if (!isNumber(current)) {
    return <Box>N/A</Box>;
  }

  const progress = Math.round((current / total) * 100);
  const label = showPercentage ? `${progress}%` : `${current}/${total}`;

  return (
    <Box display="flex">
      <Box position="relative" display="inline-flex">
        <CircularProgress
          data-level={checkResult.event.params.result}
          variant="determinate"
          value={progress}
          className={styles.circleIndeterminate}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            className={styles.label}
            variant="caption"
            component="div"
            color="textSecondary"
          >
            {label}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const renderer: CheckResultRenderer = {
  type: EVENT_TYPE,
  component: checkResult => (
    <Percentage checkResult={checkResult as PercentageProps['checkResult']} />
  ),
  description: checkResult => (
    <RenderDescription checkResult={checkResult as CustomCheckResult} />
  ),
};
