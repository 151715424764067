import { ItemCardHeader, MarkdownContent } from '@backstage/core-components';

import { BackstageTheme } from '@backstage/theme';
import {
  Card,
  CardActions,
  CardMedia,
  IconButton,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeTypeToTheme } from './headerLib';
import React from 'react';

const useStyles = makeStyles<
  BackstageTheme,
  { fontColor: string; backgroundImage: string }
>(theme => ({
  cardHeader: {
    position: 'relative',
  },
  title: {
    backgroundImage: ({ backgroundImage }) => backgroundImage,
    color: ({ fontColor }) => fontColor,
  },
  box: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 10,
    '-webkit-box-orient': 'vertical',
  },
  label: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: '0.65rem',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    lineHeight: 1,
    paddingBottom: '0.2rem',
  },
  linksLabel: {
    padding: '0 16px',
  },
  description: {
    '& p': {
      margin: '0px',
    },
  },
  leftButton: {
    marginRight: 'auto',
  },
  starButton: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    padding: '0.25rem',
    color: ({ fontColor }) => fontColor,
  },
}));

interface HeaderCardProps {
  title: string;
  description: string;
  label: string;
  href: string;
}

export const HeaderCard = (props: HeaderCardProps) => {
  const backstageTheme = useTheme<BackstageTheme>();
  const { title, description, label, href } = props;
  const themeId = makeTypeToTheme(label);
  const theme = backstageTheme.getPageTheme({ themeId });
  const classes = useStyles({
    fontColor: theme.fontColor,
    backgroundImage: theme.backgroundImage,
  });

  return (
    <Card>
      <CardMedia className={classes.cardHeader}>
        <ItemCardHeader title={title} classes={{ root: classes.title }}>
          <MarkdownContent
            className={classes.description}
            content={description}
          />
          <CardActions>
            <IconButton aria-label="go" color="inherit" href={href}>
              <ArrowForwardIcon />
            </IconButton>
          </CardActions>
        </ItemCardHeader>
      </CardMedia>
    </Card>
  );
};
