import { Entity, RELATION_HAS_PART } from '@backstage/catalog-model';
import {
  asResourceEntities,
  resourceEntityColumns,
  resourceEntityHelpLink,
} from './presets';
import { InfoCardVariants } from '@backstage/core-components';
import React from 'react';
import { RelatedEntitiesCard } from '../../RelatedEntitiesCard';

export interface DomainHasResourceCardProps {
  variant?: InfoCardVariants;
  title?: string;
  entities?: Entity[];
}

export const DomainHasResourceCard = (props: DomainHasResourceCardProps) => {
  const {
    variant = 'gridItem',
    title = 'Resources',
    entities: systems,
  } = props;

  return (
    <RelatedEntitiesCard
      variant={variant}
      title={title}
      entityKind="Resource"
      relationType={RELATION_HAS_PART}
      columns={resourceEntityColumns}
      emptyMessage="No resource is part of this domain"
      emptyHelpLink={resourceEntityHelpLink}
      asRenderableEntities={asResourceEntities}
      entities={systems}
    />
  );
};
