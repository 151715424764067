import { Entity } from '@backstage/catalog-model';
import { useEntityList } from '@backstage/plugin-catalog-react';
import React, { useEffect, useMemo } from 'react';

import {
  CustomFilters,
  EntityAnnotationsFilter,
} from '../../../catalog/filters';
import { AutocompleteFilter } from '../AutocompleteFilter';
import { useSelectAnnotation } from '../utils';
import { EXCLUDED_ANNOTATIONS } from '../constants';

export interface EntityAnnotationsPickerProps {
  hidden?: boolean;
}

export const EntityAnnotationsPicker = (
  props: EntityAnnotationsPickerProps,
) => {
  const { hidden } = props;

  const {
    backendEntities,
    filters,
    updateFilters,
    queryParameters: { annotations: parameters },
  } = useEntityList<CustomFilters>();

  const { selectedAnnotations, setSelectedAnnotations } = useSelectAnnotation(
    filters,
    'annotations',
    parameters,
  );

  const availableAnnotations = useMemo(
    () =>
      [
        ...new Set(
          backendEntities
            .map((e: Entity) => Object.keys(e.metadata.annotations ?? {}))
            .flat()
            .filter(a => !EXCLUDED_ANNOTATIONS.includes(a)),
        ),
      ].sort((a, b) => a.localeCompare(b)),
    [backendEntities],
  );

  useEffect(() => {
    updateFilters({
      annotations:
        selectedAnnotations.length && availableAnnotations.length
          ? new EntityAnnotationsFilter(selectedAnnotations, false)
          : undefined,
    });
  }, [selectedAnnotations, updateFilters, availableAnnotations]);

  // Don't show "filter" if there are no values
  if (!availableAnnotations.length) return null;
  return hidden ? null : (
    <AutocompleteFilter
      title="Annotations"
      tooltipDescription="Find which entities use a specific annotation"
      selectedData={selectedAnnotations}
      setSelectedData={setSelectedAnnotations}
      availableData={availableAnnotations}
    />
  );
};
