import React from 'react';
import { DocsIcon, CatalogIcon } from '@backstage/core-components';
import { AdrSearchResultListItem } from '@backstage/plugin-adr';
import { CatalogSearchResultListItem } from '@backstage/plugin-catalog';
import {
  SearchResult,
  SearchResultProps,
} from '@backstage/plugin-search-react';
import { TechDocsSearchResultListItem } from '@backstage/plugin-techdocs';
import { AnnouncementSearchResultListItem } from '@celonis/backstage-plugin-announcements';
import { ADRIcon } from '../../assets/icons/CustomIcons';

/**
 * Search results component. Define all elements of the search, techdocs, announcements, catalog etc.. here.
 * @returns
 */
export const SearchResults = (props: SearchResultProps) => {
  return (
    <SearchResult {...props}>
      <TechDocsSearchResultListItem icon={<DocsIcon />} />
      <AdrSearchResultListItem icon={<ADRIcon width={25} />} />
      <AnnouncementSearchResultListItem />
      <CatalogSearchResultListItem icon={<CatalogIcon />} />
    </SearchResult>
  );
};
