export const scorecardPalette = {
    error: {
        main: '#f44336',
    },
    warning: {
        main: '#ff9800',
    },
    success: {
        main: '#4caf50',
    },
};