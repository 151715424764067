import {
  Entity,
  RELATION_OWNED_BY,
  RELATION_PART_OF,
} from '@backstage/catalog-model';
import {
  EntityRefLinks,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { MarkdownContent } from '@backstage/core-components';
import React from 'react';
import { useTranslationRef } from '@backstage/core-plugin-api/alpha';
import { AboutField } from '@backstage/plugin-catalog';
import { catalogTranslationRef } from './translation';

const useStyles = makeStyles({
  description: {
    wordBreak: 'break-word',
  },
});

/**
 * Props for {@link AboutContent}.
 *
 * @public
 */
export interface AboutContentProps {
  entity: Entity;
}

export function MyAboutContent(props: AboutContentProps) {
  const { entity } = props;
  const classes = useStyles();
  const { t } = useTranslationRef(catalogTranslationRef);

  const isSystem = entity.kind.toLocaleLowerCase('en-US') === 'system';
  const isResource = entity.kind.toLocaleLowerCase('en-US') === 'resource';
  const isComponent = entity.kind.toLocaleLowerCase('en-US') === 'component';
  const isAPI = entity.kind.toLocaleLowerCase('en-US') === 'api';
  const isTemplate = entity.kind.toLocaleLowerCase('en-US') === 'template';
  const isGroup = entity.kind.toLocaleLowerCase('en-US') === 'group';

  const partOfSystemRelations = getEntityRelations(entity, RELATION_PART_OF, {
    kind: 'system',
  });
  const partOfComponentRelations = getEntityRelations(
    entity,
    RELATION_PART_OF,
    {
      kind: 'component',
    },
  );
  const partOfDomainRelations = getEntityRelations(entity, RELATION_PART_OF, {
    kind: 'domain',
  });
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

  return (
    <Grid container>
      <AboutField
        label={t('aboutCard.descriptionField.label')}
        gridSizes={{ xs: 12 }}
      >
        <MarkdownContent
          className={classes.description}
          content={
            entity?.metadata?.description ||
            t('aboutCard.descriptionField.value')
          }
        />
      </AboutField>
      <AboutField
        label={t('aboutCard.ownerField.label')}
        value={t('aboutCard.ownerField.value')}
        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
      >
        {ownedByRelations.length > 0 && (
          <EntityRefLinks entityRefs={ownedByRelations} defaultKind="group" />
        )}
      </AboutField>
      {(isSystem || partOfDomainRelations.length > 0) && (
        <AboutField
          label={t('aboutCard.domainField.label')}
          value={t('aboutCard.domainField.value')}
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        >
          {partOfDomainRelations.length > 0 && (
            <EntityRefLinks
              entityRefs={partOfDomainRelations}
              defaultKind="domain"
            />
          )}
        </AboutField>
      )}
      {(isAPI ||
        isComponent ||
        isResource ||
        partOfSystemRelations.length > 0) && (
        <AboutField
          label={t('aboutCard.systemField.label')}
          value={t('aboutCard.systemField.value')}
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        >
          {partOfSystemRelations.length > 0 && (
            <EntityRefLinks
              entityRefs={partOfSystemRelations}
              defaultKind="system"
            />
          )}
        </AboutField>
      )}
      {isComponent && partOfComponentRelations.length > 0 && (
        <AboutField
          label={t('aboutCard.parentComponentField.label')}
          value={t('aboutCard.parentComponentField.value')}
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        >
          <EntityRefLinks
            entityRefs={partOfComponentRelations}
            defaultKind="component"
          />
        </AboutField>
      )}
      {(isAPI ||
        isComponent ||
        isResource ||
        isTemplate ||
        isGroup ||
        typeof entity?.spec?.type === 'string') && (
        <AboutField
          label={t('aboutCard.typeField.label')}
          value={entity?.spec?.type as string}
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        />
      )}
      {(isAPI ||
        isComponent ||
        typeof entity?.spec?.lifecycle === 'string') && (
        <AboutField
          label={t('aboutCard.lifecycleField.label')}
          value={entity?.spec?.lifecycle as string}
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        />
      )}

      {(isAPI || typeof entity?.spec?.audience === 'string') && (
        <AboutField
          label={t('aboutCard.audienceField.label')}
          value={
            (entity?.spec?.audience as string) ??
            t('aboutCard.audienceField.value')
          }
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        />
      )}
      {(isComponent || typeof entity?.spec?.tier === 'string') &&
        entity?.spec?.type === 'service' && (
          <AboutField
            label={t('aboutCard.tierField.label')}
            value={
              (entity.spec.tier as string) ?? t('aboutCard.tierField.value')
            }
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
          />
        )}
      <AboutField
        label={t('aboutCard.tagsField.label')}
        value={t('aboutCard.tagsField.value')}
        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
      >
        {(entity?.metadata?.tags || []).map(tag => (
          <Chip key={tag} size="small" label={tag} />
        ))}
      </AboutField>
    </Grid>
  );
}
