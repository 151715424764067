import React, { useState } from 'react';
import { CodeSnippet } from '@backstage/core-components';
import { CheckResult } from '@backstage/plugin-tech-insights-common';
import { TechInsightCheck } from '@celonis/plugin-tech-insights-backend-module-jsonfc';
import { Button, Collapse, Tabs, Tab, Box } from '@material-ui/core';
import { RunChecksError } from '../types';
import { isNil } from 'lodash';
import { Alert } from '@material-ui/lab';

const InformationSection = (props: { code?: any }) => (
  <CodeSnippet
    language="json"
    text={JSON.stringify(props.code, null, 2)}
    showCopyCodeButton
  />
);

const CheckError = (props: { error?: RunChecksError }) => (
  <>
    <Alert severity="error">
      An error happened while computing the check results. Please wait for the
      next run or check the error and contact the Scorecard Owner
    </Alert>
    <InformationSection code={props.error} />
  </>
);

export const AdvancedCheckInfo = (props: {
  result: CheckResult;
  checkDefinition?: TechInsightCheck;
  checkError?: RunChecksError;
}) => {
  const { result, checkDefinition, checkError } = props;

  const [showAdvanced, setShowAdvanced] = useState(false);
  const handleToggleAdvanced = () => {
    setShowAdvanced(!showAdvanced);
  };

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Button variant="outlined" onClick={handleToggleAdvanced}>
        {showAdvanced ? 'Hide Advanced' : 'Show Advanced'}
      </Button>
      <Collapse in={showAdvanced}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
        >
          <Tab label="Check Result Payload" />
          <Tab label="Check Definition" />
          {!isNil(checkError) && <Tab label="Check Computation Error" />}
        </Tabs>
        {selectedTab === 0 && (
          <Box p={3}>
            <InformationSection code={result} />
          </Box>
        )}
        {selectedTab === 1 && (
          <Box p={3}>
            <InformationSection code={checkDefinition} />
          </Box>
        )}
        {selectedTab === 2 && (
          <Box p={3}>
            <CheckError error={checkError} />
          </Box>
        )}
      </Collapse>
    </>
  );
};
