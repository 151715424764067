import { renderer as trafficLightsRenderer } from './TrafficLights';
import { renderer as percentageRenderer } from './Percentage';
import { renderer as booleanRenderer } from './Boolean';
import * as nunjucks from 'nunjucks';
import type { CheckResultRenderer, CheckResult as OverridenCheckResult } from './types';
import { findMatchingRenderer, renderCheckName } from './TemplatingEngine';

nunjucks.configure({
  autoescape: true,
});

const supportedRendererTypes = [trafficLightsRenderer, percentageRenderer, booleanRenderer];

export const eventBasedRenderer: CheckResultRenderer = {
  type: 'json-rules-engine',
  component(checkResult: OverridenCheckResult) {
    const renderer = findMatchingRenderer(checkResult, supportedRendererTypes);
    checkResult.check.name = renderCheckName(checkResult);
    return renderer.component(checkResult);
  },
  description(checkResult: OverridenCheckResult) {
    const renderer = findMatchingRenderer(checkResult, supportedRendererTypes);

    if (renderer.description) {
      return renderer.description(checkResult);
    }

    return '';
  }
};
