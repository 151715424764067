import { Typography, TypographyProps } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ServiceAlignmentHelpDialog } from '../../MissingDialog';
import * as Constants from '../../constants';

interface LinkToWebsiteProps extends TypographyProps {
  url: string;
  message: string;
  children?: ReactNode;
}

interface NotFoundProps extends TypographyProps {
  field: string;
  message: string;
  fieldTitle: string;
  value: any;
  tableValue?: string;
  children?: ReactNode;
}

export function LinkToWebsite(props: Readonly<LinkToWebsiteProps>) {
  const { children, color = 'primary' } = props;
  return (
    <Typography variant="body2" color={color}>
      <Link target="_blank" to={props.url}>
        {props.message}
      </Link>
      {children}
    </Typography>
  );
}

export function NotFound({
  field,
  message,
  fieldTitle,
  value,
  tableValue = 'Missing',
  children,
  color = 'secondary',
}: Readonly<NotFoundProps>) {
  return (
    <Typography variant="body2" color={color}>
      {tableValue}
      <ServiceAlignmentHelpDialog
        message={message}
        field={field}
        fieldTitle={fieldTitle}
        value={value}
      >
        {children}
      </ServiceAlignmentHelpDialog>
    </Typography>
  );
}

export function BrokenLink(
  props: Readonly<{
    app: string;
  }>,
) {
  return (
    <Alert severity="info">
      <AlertTitle>If {props.app} link is broken</AlertTitle>
      Please refer to{' '}
      <Link target="_blank" to={Constants.LINK_TO_CATALOG_DOCS}>
        {' '}
        <strong>this documentation</strong>
      </Link>{' '}
      to update the link to your {props.app}.
    </Alert>
  );
}
