import React, { ReactNode, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import HelpOutline from '@material-ui/icons/HelpOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Divider, Typography, makeStyles } from '@material-ui/core';
import { LINK_TO_CATALOG_DOCS } from './constants';
import { useEntity } from '@backstage/plugin-catalog-react';
import { ANNOTATION_EDIT_URL } from '@backstage/catalog-model';
import { StructuredMetadataTable } from '@backstage/core-components';

const useStyles = makeStyles({
  dialogIcon: {
    transform: 'scale(0.6,0.6)',
    top: '-0.3em',
    position: 'relative',
    right: '0.5em',
  },
  dividerWithMargin: {
    paddingTop: '20px,',
  },
});

interface ToolipProps {
  fieldTitle?: string;
  message: string;
  field: string;
  value: ReactNode;
  children?: ReactNode;
}

const TooltipContent = (props: ToolipProps) => {
  const { message, fieldTitle, field, children, value } = props;
  const metadata = {
    title: fieldTitle,
    description: message,
    field: (
      <Typography style={{ fontFamily: 'Courier New, monospace' }}>
        {field}
      </Typography>
    ),
    value: value,
  };
  return (
    <>
      <StructuredMetadataTable metadata={metadata} dense={false} />
      <Divider style={{ marginTop: '5%', marginBottom: '5%' }} />
      {children}
    </>
  );
};

export const ServiceAlignmentHelpDialog = (props: ToolipProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { entity } = useEntity();
  const editURl = entity.metadata.annotations![ANNOTATION_EDIT_URL];

  const handleClose = () => {
    setOpen(false);
  };

  const docsOnClick = () => {
    window.open(LINK_TO_CATALOG_DOCS, '_blank');
  };
  const templateOnClick = () => {
    window.open(editURl, '_blank');
  };
  return (
    <>
      <IconButton className={classes.dialogIcon} onClick={() => setOpen(true)}>
        <HelpOutline />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Details</DialogTitle>
        <DialogContent>
          <Typography variant="body2" component="div">
            <TooltipContent {...props} />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={docsOnClick} color="primary">
            Read Docs
          </Button>
          <Button onClick={templateOnClick} color="primary">
            Edit Definition
          </Button>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
