import { useApi } from '@backstage/core-plugin-api';
import { techInsightsApiRef } from '@backstage/plugin-tech-insights';
import { Grid, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';
import { Group, techinsightsGroupsApiRef } from '../../techInsights-groups';
import { useEntity } from '@backstage/plugin-catalog-react';
import { getCompoundEntityRef } from '@backstage/catalog-model';
import { ScorecardPageEmpty } from './ScorecardPageEmpty';
import { ScorecardPage } from './ScorecardPage';
import { CustomCheckResult, ScorecardGroupsContent } from './types';
import type { RunChecksResponse } from '@celonis/plugin-tech-insights-backend-module-jsonfc';

interface GroupCheckCardProps {
  groups: Group[];
}

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
    flexWrap: 'nowrap',
  },

  toogleLink: {
    fontSize: 'initial',
    color: '#11689e',
    cursor: 'pointer',
    textAlign: 'end',
  },

  contentScorecards: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const useGetGroups = () => {
  const techinsightsGroupsApi = useApi(techinsightsGroupsApiRef);

  const { loading, error, value } = useAsync(async () => {
    return techinsightsGroupsApi.getScorecards();
  }, []);

  return {
    loading,
    error,
    data: value,
  };
};

const useGetCheckResult = (groups: Group[]) => {
  const { entity } = useEntity();
  const api = useApi(techInsightsApiRef);
  const scorecardGroupContent: ScorecardGroupsContent[] = [];

  // Loading all groups checks into one array
  const groupsChecks = groups.flatMap(group => group.checks);

  const { loading, error, value } = useAsync(async () => {
    const checkResults = (await api.runChecks(
      getCompoundEntityRef(entity),
      groupsChecks as unknown as string[],
    )) as unknown as RunChecksResponse;

    const result = checkResults.checks;
    const errors = checkResults.errors;

    if (result.length === 0) {
      return [];
    }

    // Mapping each group checks results to its group
    // Each group will have its own array of checks
    groups.forEach(group => {
      const groupCheckResult = result.filter(check =>
        group.checks.some(groupCheck => groupCheck.id === check.check.id),
      );

      if (groupCheckResult.length === 0) {
        return;
      }

      const successfulChecks = groupCheckResult.filter(
        current => current.result,
      );

      const failedChecks = groupCheckResult.filter(current => !current.result);

      scorecardGroupContent.push({
        title: group.title,
        description: group.description,
        failedChecks: failedChecks as CustomCheckResult[],
        successfulChecks: successfulChecks as CustomCheckResult[],
        checksDefinition: group.checks,
        okPercentage: 0,
        features: group.features,
        errors: errors,
      });
    });

    return scorecardGroupContent;
  }, [api, entity, JSON.stringify(groupsChecks), JSON.stringify(groups)]);

  return {
    loading,
    error,
    data: (value as ScorecardGroupsContent[]) ?? [],
  };
};

const GroupCheckCard: React.FC<GroupCheckCardProps> = ({ groups }) => {
  const { loading, error, data } = useGetCheckResult(groups);
  if (loading) {
    return <h1>Loading...</h1>;
  }

  if (error) {
    return (
      <Alert severity="error" title="Error">
        {error.message}
      </Alert>
    );
  }

  return data.length > 0 ? (
    <ScorecardPage groupsContent={data} />
  ) : (
    <ScorecardPageEmpty />
  );
};

export const ScoreCard = () => {
  const groupsResponse = useGetGroups();
  const classes = useStyles();

  const { loading, error, data } = groupsResponse;

  if (loading) {
    return <h1>Loading...</h1>;
  }

  if (error) {
    return (
      <Alert severity="error" title="Error">
        {error.message}
      </Alert>
    );
  }

  const groups = data ?? [];

  return (
    <Grid
      className={classes.gridItemCard}
      container
      spacing={1}
      alignItems="stretch"
      justifyContent="space-evenly"
    >
      <GroupCheckCard groups={groups} />
    </Grid>
  );
};
