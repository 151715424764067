import { EntityRefLinks } from '@backstage/plugin-catalog-react';
import React from 'react';
import { ServiceAlignmentHelpDialog } from '../../MissingDialog';

// Note: Name cannot be undefined. The entity won't get imported if that happens
export function NameData(props: Readonly<{ entityRef: string }>) {
  const { entityRef } = props;
  return (
    <>
      <EntityRefLinks entityRefs={[entityRef]} />
      <ServiceAlignmentHelpDialog
        message="Corresponds to the name of this service and will be unique across Celodrome."
        fieldTitle="Name"
        field="metadata.name"
        value={<EntityRefLinks entityRefs={[entityRef]} />}
      />
    </>
  );
}
