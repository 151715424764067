/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    fill: '#ffffff',
    width: 'auto',
    height: 28,
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <path d="M300.88,14.05c31.45,0,63.12,15.09,91.6,43.63,23.88,23.95,44.2,55.94,58.76,92.52,14.67,36.88,22.38,75.71,22.29,112.3a225.26,225.26,0,0,1-64,156.68C367.49,462.24,310.83,486,250,486c-62.87,0-120.07-20.28-161.08-57.12-40.25-36.17-62.42-85.95-62.42-140.15,0-68.31,31.87-87,84.67-118,16.72-9.81,35.67-20.92,55.63-35C212,103.86,232,73.49,246.5,51.32,262.78,26.5,271,14.05,300.88,14.05m0-9.32c-65.62,0-45.68,57.33-139.48,123.41C79.59,185.77,17.15,191.76,17.15,288.68c0,116.75,97.11,206.59,232.82,206.59,133,0,232.58-108,232.88-232.75.29-120.92-82.37-257.79-182-257.79Z"/>
      <path d="M315.42,202.27Q336.27,219.1,341.34,248H305.28q-3-15.49-14.45-24.29t-28.76-8.79q-19.66,0-32.78,13.86t-13.12,40.67q0,26.82,13.11,40.83t32.78,14q17.28,0,28.76-8.94t14.46-24.43h36.05q-5.07,28.9-25.93,45.89t-52.75,17q-23.83,0-42.16-10.13a72,72,0,0,1-28.76-29.2q-10.44-19.06-10.43-45,0-25.62,10.44-44.69a72.05,72.05,0,0,1,28.76-29.21q18.33-10.12,42.17-10.13Q294.56,185.43,315.42,202.27Z"/>
    </svg>
  );
};

export default LogoIcon;
