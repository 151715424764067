import { Entity, RELATION_HAS_PART } from '@backstage/catalog-model';
import {
  asComponentEntities,
  componentEntityColumns,
  componentEntityHelpLink,
} from './presets';
import { InfoCardVariants } from '@backstage/core-components';
import React from 'react';
import { RelatedEntitiesCard } from '../../RelatedEntitiesCard';

export interface DomainHasComponentCardProps {
  variant?: InfoCardVariants;
  title?: string;
  entities?: Entity[];
}

export const DomainHasComponentCard = (props: DomainHasComponentCardProps) => {
  const {
    variant = 'gridItem',
    title = 'Components',
    entities: systems,
  } = props;

  return (
    <RelatedEntitiesCard
      variant={variant}
      title={title}
      entityKind="Component"
      relationType={RELATION_HAS_PART}
      columns={componentEntityColumns}
      emptyMessage="No component is part of this domain"
      emptyHelpLink={componentEntityHelpLink}
      asRenderableEntities={asComponentEntities}
      entities={systems}
    />
  );
};
