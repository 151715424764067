import {
  DEFAULT_NAMESPACE,
  GroupEntity,
  UserEntity,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';
import {
  Box,
  Chip,
  createStyles,
  Grid,
  makeStyles,
  Switch,
  Theme,
  Typography,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import React, { useState } from 'react';
import useAsync from 'react-use/lib/useAsync';

import {
  Avatar,
  InfoCard,
  Progress,
  ResponseErrorPanel,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import {
  getAllDesendantMembersForGroupEntity,
  removeDuplicateEntitiesFrom,
} from '../../../helpers/helpers';

import { EntityRefLink } from '@backstage/plugin-catalog-react';
import { capitalize, isEmpty, isNil } from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: theme.shadows[2],
      borderRadius: '4px',
      overflow: 'visible',
      position: 'relative',
      margin: theme.spacing(4, 1, 1),
      flex: '1',
      minWidth: '0px',
    },
  }),
);

const MemberComponent = (props: { member: UserEntity }) => {
  const classes = useStyles();
  const {
    metadata: { name: metaName, description, tags },
    spec: { profile },
  } = props.member;
  const displayName = profile?.displayName ?? metaName;

  // Assumes users only have one tag!
  const jobFamily =
    isNil(tags) || isEmpty(tags) ? undefined : capitalize(tags[0]);
  return (
    <Grid item container xs={12} sm={6} md={4} xl={2}>
      <Box className={classes.card}>
        <Box
          display="flex"
          flexDirection="column"
          m={3}
          alignItems="center"
          justifyContent="center"
        >
          <Avatar
            displayName={displayName}
            picture={profile?.picture}
            customStyles={{
              position: 'absolute',
              top: '-2rem',
            }}
          />
          <Box
            pt={2}
            sx={{
              width: '100%',
            }}
            textAlign="center"
          >
            <Typography variant="h6">
              <EntityRefLink
                data-testid="user-link"
                entityRef={props.member}
                title={displayName}
              />
            </Typography>

            {description && (
              <Typography variant="subtitle2">{description}</Typography>
            )}
            {jobFamily && (
              <Chip size="small" label={jobFamily} color="default" />
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

/** @public */
export const MembersListCard = (props: {
  memberDisplayTitle?: string;
  pageSize?: number;
  showAggregateMembersToggle?: boolean;
}) => {
  const {
    memberDisplayTitle = 'Members',
    pageSize = 50,
    showAggregateMembersToggle,
  } = props;

  const { entity: groupEntity } = useEntity<GroupEntity>();
  const {
    metadata: { name: groupName, namespace: grpNamespace },
    spec: { profile },
  } = groupEntity;
  const catalogApi = useApi(catalogApiRef);

  const displayName = profile?.displayName ?? groupName;

  const groupNamespace = grpNamespace || DEFAULT_NAMESPACE;

  const [page, setPage] = React.useState(1);
  const pageChange = (_: React.ChangeEvent<unknown>, pageIndex: number) => {
    setPage(pageIndex);
  };

  const [showAggregateMembers, setShowAggregateMembers] = useState(false);

  const { loading: loadingDescendantMembers, value: descendantMembers } =
    useAsync(async () => {
      if (!showAggregateMembersToggle) {
        return [] as UserEntity[];
      }

      return await getAllDesendantMembersForGroupEntity(
        groupEntity,
        catalogApi,
      );
    }, [catalogApi, groupEntity, showAggregateMembersToggle]);
  const {
    loading,
    error,
    value: directMembers,
  } = useAsync(async () => {
    const membersList = await catalogApi.getEntities({
      filter: {
        'relations.memberof': [
          stringifyEntityRef({
            kind: groupEntity.kind,
            namespace: groupNamespace.toLocaleLowerCase('en-US'),
            name: groupName.toLocaleLowerCase('en-US'),
          }),
        ],
      },
    });

    return membersList.items as UserEntity[];
  }, [catalogApi, groupEntity]);

  const members = removeDuplicateEntitiesFrom(
    [
      ...(directMembers ?? []),
      ...(descendantMembers && showAggregateMembers ? descendantMembers : []),
    ].sort((a, b) =>
      stringifyEntityRef(a).localeCompare(stringifyEntityRef(b)),
    ),
  ) as UserEntity[];

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  const nbPages = Math.ceil((members?.length || 0) / pageSize);
  const paginationLabel = nbPages < 2 ? '' : `, page ${page} of ${nbPages}`;

  const pagination = (
    <Pagination
      count={nbPages}
      page={page}
      onChange={pageChange}
      showFirstButton
      showLastButton
    />
  );

  return (
    <Grid item>
      <InfoCard
        title={`${memberDisplayTitle} (${
          members?.length || 0
        }${paginationLabel})`}
        subheader={`of ${displayName}`}
        {...(nbPages <= 1 ? {} : { actions: pagination })}
      >
        {showAggregateMembersToggle && (
          <>
            Direct Members
            <Switch
              color="primary"
              checked={showAggregateMembers}
              onChange={() => {
                setShowAggregateMembers(!showAggregateMembers);
              }}
              inputProps={{ 'aria-label': 'Users Type Switch' }}
            />
            Aggregated Members
          </>
        )}
        {showAggregateMembers && loadingDescendantMembers ? (
          <Progress />
        ) : (
          <Grid container spacing={3}>
            {members && members.length > 0 ? (
              members
                .slice(pageSize * (page - 1), pageSize * page)
                .map(member => (
                  <MemberComponent member={member} key={member.metadata.uid} />
                ))
            ) : (
              <Box p={2}>
                <Typography>
                  This group has no {memberDisplayTitle.toLocaleLowerCase()}.
                </Typography>
              </Box>
            )}
          </Grid>
        )}
      </InfoCard>
    </Grid>
  );
};
