/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// FETCHED FROM https://github.com/backstage/backstage/blob/474cd71ab8af7b60d814de7493979daa1718d372/plugins/tech-insights/src/components/ScorecardsInfo/ScorecardInfo.tsx

import React, { ReactNode } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { InfoCard } from '@backstage/core-components';
import { BackstageTheme } from '@backstage/theme';
import { Alert } from '@material-ui/lab';
import { ScorecardsList } from './ScorecardsList';
import { CheckResult, ScorecardGroupsContent } from './types';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme: BackstageTheme) => ({
  subheader: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(0.5),
  },
}));

const infoCard = (
  title: string,
  actions: ReactNode,
  subheader: ReactNode,
  description: string | undefined,
  className: string,
  element: JSX.Element,
) => (
  // <Grid item xs={12}>
  <InfoCard title={title} action={actions} subheader={subheader}>
    {description && (
      <Typography className={className} variant="body1" gutterBottom>
        {description}
      </Typography>
    )}
    <Grid item xs={12}>
      {element}
    </Grid>
  </InfoCard>
  // </Grid>
);

export const ScorecardInfo = (props: {
  checkResults: CheckResult[];
  title: string;
  actions: ReactNode;
  subheader: ReactNode;
  description?: string;
  group: ScorecardGroupsContent;
}) => {
  const { checkResults, title, description, group, actions, subheader } = props;
  const classes = useStyles();

  const element = isEmpty(checkResults) ? (
    <Alert severity="warning">No checks have any data yet.</Alert>
  ) : (
    <ScorecardsList checkResults={checkResults} group={group} />
  );
  return infoCard(
    title,
    actions,
    subheader,
    description,
    classes.subheader,
    element,
  );
};
