import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import HelpOutline from '@material-ui/icons/HelpOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Divider, Typography, makeStyles } from '@material-ui/core';
import { CheckResult, ScorecardGroupsContent } from '../types';
import { AdvancedCheckInfo } from './AdvanceCheckInformation';
import { CheckDialogContent } from './DialogContent';
import { isNil } from 'lodash';

const useStyles = makeStyles({
  dialogIcon: {
    transform: 'scale(0.6,0.6)',
    top: '-0.3em',
    position: 'relative',
    right: '0.5em',
  },
});

export const CheckInfoDialog = (props: {
  result: CheckResult;
  group: ScorecardGroupsContent;
}) => {
  const [open, setOpen] = useState(false);
  const { result, group } = props;

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const checkErrorId = Object.keys(group.errors).find(
    key => key === result.check.id,
  );
  const internalErrorData = isNil(checkErrorId)
    ? undefined
    : group.errors[checkErrorId];

  const checkDefinition = group.checksDefinition.find(
    check => check.id === result.check.id,
  );

  return (
    <>
      <IconButton className={classes.dialogIcon} onClick={() => setOpen(true)}>
        <HelpOutline />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle>Check Details</DialogTitle>
        <DialogContent>
          <Typography variant="body2" component="div">
            <CheckDialogContent
              result={result}
              errorMessage={internalErrorData?.message}
            />
            <Divider style={{ border: '1', margin: '10px' }} />
            <AdvancedCheckInfo
              result={result}
              checkDefinition={checkDefinition}
              checkError={internalErrorData}
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
