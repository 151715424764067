import { Typography, makeStyles } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import { ServiceAlignmentHelpDialog } from '../../MissingDialog';

const useStyles = makeStyles({
  displayLinebreak: {
    whiteSpace: 'pre-line',
  },
});

// Note: Lifecycle cannot be undefined. The entity won't get imported if that happens
export function LifecycleData(
  props: Readonly<{
    lifecycle?: string;
  }>,
): React.JSX.Element {
  const { lifecycle } = props;
  const classes = useStyles();
  const alertMessage = `Production - Follows our guidelines for higher standards for a service in production 
     \nExperimental - Not yet migrated to a production service and still in experimental phase 
     \nDeprecated - This service is no more in use`;

  return (
    <Typography variant="body2">
      {lifecycle}
      <ServiceAlignmentHelpDialog
        fieldTitle="Lifecycle"
        message="Value can be any of [ production | experimental | deprecated ]."
        field="spec.lifecycle"
        value={lifecycle}
      >
        <Alert severity="info">
          <AlertTitle>Lifecycle of a service</AlertTitle>
          <div className={classes.displayLinebreak}>{alertMessage}</div>
        </Alert>
      </ServiceAlignmentHelpDialog>
    </Typography>
  );
}
