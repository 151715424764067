import { Typography, makeStyles } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import { ServiceAlignmentHelpDialog } from '../../MissingDialog';
import { isNil } from 'lodash';
import { LinkToWebsite, NotFound } from '../Common/common';
import * as Constants from '../../constants';

const useStyles = makeStyles({
  displayLinebreak: {
    whiteSpace: 'pre-line',
  },
});

export function TierData(
  props: Readonly<{
    tier?: string;
    lifecycle?: string;
  }>,
): React.JSX.Element {
  const { tier, lifecycle } = props;
  const classes = useStyles();
  const alertMessage = `Should be defined if lifecycle is production.
     \n0 - Services and/or systems that have a direct impact on the Organisation's Core Mission and/or its Revenue.
     \n1 - Services that when down cause a degraded customer experience, but do not prevent the customer from interacting with celonis platform
     \n2 - Services that when down have almost unnoticeable customer or business impact.`;

  const description =
    'Classification of the criticality of a group of services based on their characteristics & unavailability Impact. Values can be any of [ 0 | 1 | 2 ]';

  // Production + tier set
  if (lifecycle === 'production' && !isNil(tier)) {
    return (
      <Typography variant="body2">
        {tier}
        <ServiceAlignmentHelpDialog
          fieldTitle="Tier"
          message={description}
          field="spec.tier"
          value={tier}
        >
          <Alert severity="info">
            <AlertTitle>Tier of a service</AlertTitle>
            <div className={classes.displayLinebreak}>{alertMessage}</div>
          </Alert>
        </ServiceAlignmentHelpDialog>
      </Typography>
    );
  }

  // Production + tier not set
  if (lifecycle === 'production' && isNil(tier)) {
    return (
      <NotFound
        message={description}
        field="spec.tier"
        fieldTitle="Tier"
        value={
          <LinkToWebsite
            url={Constants.LINK_TO_CATALOG_DOCS}
            color="secondary"
            message={Constants.MISSING_TIER_IN_PRODUCTION}
          />
        }
        tableValue="Required"
        color="secondary"
        children={
          <Alert severity="info">
            <AlertTitle>Tier of a service</AlertTitle>
            <div className={classes.displayLinebreak}>{alertMessage}</div>
          </Alert>
        }
      />
    );
  }

  // Not production
  const tableVal = isNil(tier) ? 'Not applicable' : tier;
  return (
    <NotFound
      message={description}
      field="spec.tier"
      fieldTitle="Tier"
      value={Constants.MISSING_TIER_NOT_PRODUCTION}
      tableValue={tableVal}
      color="textSecondary"
      children={
        <Alert severity="info">
          <AlertTitle>Tier of a service</AlertTitle>
          <div className={classes.displayLinebreak}>{alertMessage}</div>
        </Alert>
      }
    />
  );
}
