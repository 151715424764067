import React from 'react';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import { Chip, Link } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { isNil, last } from 'lodash';

export interface ArgoCdLinkProps {
  appName: string;
}
interface ArgoImagesProps {
  images?: string[];
}

export const ArgoCdLink: React.FC<ArgoCdLinkProps> = ({ appName }) => {
  const config = useApi(configApiRef);
  const argocdUrl = config.getString('app.argocdUrl');
  const name = appName;

  const url = `${argocdUrl}/applications/${name}`;

  return (
    <Link href={url} target="_blank">
      View <OpenInNewIcon style={{ fontSize: 14 }} />
    </Link>
  );
};

export const ArgoCdImages: React.FC<ArgoImagesProps> = ({ images }) => {
  if (isNil(images) || images.length === 0) return null;

  const allowedRegistries = {
    openshift: 'image-registry.openshift-image-registry.svc:5000',
    amazon: 'amazonaws.com',
  };

  const exclusionImages = [
    'vendor/docker.io',
    'postgresql-client',
    'java-security-agent',
    'dockerhub',
    'kubectl',
  ];

  const filteredImages = images.filter(
    image => !imageExclusion(image, exclusionImages),
  );

  return filteredImages.map((image, index) => {
    const registry = Object.entries(allowedRegistries).find(([_, value]) =>
      image.includes(value),
    );
    if (registry) {
      return (
        <Chip
          key={`${encodeURIComponent(image)}-${index}`}
          label={lastSegment(image)}
        />
      );
    }
    return null; // If the image doesn't match any allowed registry, return null
  });
};

function imageExclusion(image: string, exclusionImages: string[]): boolean {
  return exclusionImages.some(excludedImage => image.includes(excludedImage));
}

function lastSegment(image: string): string {
  return last(image.split('/')) ?? '';
}
