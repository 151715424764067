import { DependencyGraphTypes } from '@backstage/core-components';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { Theme } from '@material-ui/core';
import { configApiRef, useApi, useApp } from '@backstage/core-plugin-api';
import WorkIcon from '@material-ui/icons/Work';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { EntityNodeData } from '@backstage/plugin-catalog-graph';
import { useEntityData } from './getEntityData';
import { getNodeText } from './helpers';
import { capitalize, isNil } from 'lodash';
import { Entity, GroupEntity } from '@backstage/catalog-model';

export function NodeIcon({
  kind,
  entity,
  ...props
}: {
  kind: string;
  entity?: Entity;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  className?: string;
}) {
  const orgTeams = ['tribe', 'subtribe', 'pillar', 'org'];
  const internalTeams = ['contact-points', 'security-group'];

  const typeIcon = () => {
    const groupEntity = entity as GroupEntity;
    if (internalTeams.includes(groupEntity.spec.type)) return 'internalGroup';
    if (orgTeams.includes(groupEntity.spec.type)) return groupEntity.spec.type;
    return `kind:${kind.toLocaleLowerCase('en-US')}`;
  };

  const app = useApp();
  const systemIconName =
    !isNil(entity) && kind.toLocaleLowerCase('en-US') === 'group'
      ? typeIcon()
      : `kind:${kind.toLocaleLowerCase('en-US')}`;

  const IconKind = app.getSystemIcon(systemIconName) ?? WorkIcon;
  return <IconKind {...props} />;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    node: {
      fill: theme.palette.grey[300],
      stroke: theme.palette.grey[300],

      '&.primary': {
        fill: theme.palette.primary.light,
        fillOpacity: 0,
        // TODO: change to theme, once we have set a Celonis theme for the app
        // fill: theme.palette.primary.light,
        stroke: theme.palette.type === 'dark' ? 'white' : 'black',
        strokeWidth: 2,
      },
      '&.secondary': {
        // TODO: change to theme, once we have set a Celonis theme for the app
        // fill: theme.palette.secondary.light,
        // stroke: theme.palette.secondary.light,
        fill: theme.palette.type === 'dark' ? '#00A300' : '#00ff00',
        stroke: theme.palette.type === 'dark' ? '#00A300' : '#00ff00',
        fillOpacity: 0,
        strokeWidth: 3,
      },
      '&.focused': {
        // TODO: change to theme, once we have set a Celonis theme for the app
        // fill: theme.palette.secondary.light,
        fill: theme.palette.type === 'dark' ? '#00A300' : '#00ff00',
        fillOpacity: 10,
      },
    },
    text: {
      fill: theme.palette.getContrastText(theme.palette.grey[300]),

      '&.primary': {
        // TODO: change to theme, once we have set a Celonis theme for the app
        // fill: theme.palette.primary.contrastText,
        fill: theme.palette.type === 'dark' ? 'white' : 'black',
      },
      '&.secondary': {
        // TODO: change to theme, once we have set a Celonis theme for the app
        // fill: theme.palette.secondary.contrastText,
        fill: theme.palette.type === 'dark' ? 'white' : 'black',
      },
      '&.focused': {
        fontWeight: 'bold',
        fill: theme.palette.type === 'dark' ? 'white' : 'black',
      },
    },
    clickable: {
      cursor: 'pointer',
    },
    buttonText: {},
    icon: {
      fill: theme.palette.type === 'dark' ? 'white' : 'black',
      stroke: theme.palette.type === 'dark' ? 'white' : 'black',
    },
  }),
  { name: 'PluginCatalogGraphCustomNode' },
);

export function GraphNode({
  node: {
    id,
    kind,
    namespace,
    name,
    color = 'default',
    focused,
    title,
    onClick,
  },
}: DependencyGraphTypes.RenderNodeProps<EntityNodeData>) {
  const classes = useStyles();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const idRef = useRef<SVGTextElement | null>(null);

  useLayoutEffect(() => {
    // set the width to the length of the ID
    if (idRef.current) {
      let { height: renderedHeight, width: renderedWidth } =
        idRef.current.getBBox();
      renderedHeight = Math.round(renderedHeight);
      renderedWidth = Math.round(renderedWidth);

      if (renderedHeight !== height || renderedWidth !== width) {
        setWidth(renderedWidth);
        setHeight(renderedHeight);
      }
    }
  }, [width, height]);

  const padding = 10;
  const redirectionButtonSize = height * 0.9;
  const entityIconSize = height * 1.5;
  const paddedIconHeight = padding / 1.5;
  const paddedIconWidth = kind ? entityIconSize + padding : 0;
  const paddedRedirectIconWidth = redirectionButtonSize + padding;
  const paddedWidth =
    paddedRedirectIconWidth + paddedIconWidth + width + padding * 3;
  const paddedHeight = height + padding * 2;

  const entity = useEntityData(kind ?? '', name);
  const nodeTitle = getNodeText(id, title, kind, name, namespace, entity);

  const config = useApi(configApiRef);
  const handleRedirect = (event: React.MouseEvent<SVGGElement, MouseEvent>) => {
    const baseUrl = config.getString('app.baseUrl');
    const url = `${baseUrl}/catalog/${namespace}/${kind?.toLowerCase()}/${name}/org-diagram`;
    window.open(url, '_blank')!.focus();
    event.stopPropagation();
  };
  const tooltip =
    isNil(entity) || isNil(entity?.spec) ? kind : entity.spec.type;

  return (
    <g onClick={onClick} className={classNames(onClick && classes.clickable)}>
      {/* Rectangle */}
      <title>{capitalize(tooltip as string)}</title>
      <rect
        className={classNames(
          classes.node,
          color === 'primary' && 'primary',
          color === 'secondary' && 'secondary',
          color === 'secondary' && 'focused' && 'focused',
        )}
        width={paddedWidth}
        height={paddedHeight}
        rx={10}
      />
      {kind && (
        <NodeIcon
          kind={kind}
          entity={entity}
          y={paddedIconHeight}
          x={padding}
          width={entityIconSize}
          height={entityIconSize}
          className={classNames(
            classes.text,
            focused && 'focused',
            color === 'primary' && 'primary',
            color === 'secondary' && 'secondary',
          )}
        />
      )}
      {/* The content goes here */}
      <text
        ref={idRef}
        className={classNames(
          classes.text,
          focused && 'focused',
          color === 'primary' && 'primary',
          color === 'secondary' && 'secondary',
        )}
        y={paddedHeight / 2}
        x={paddedIconWidth + (width + padding * 2) / 2}
        textAnchor="middle"
        alignmentBaseline="middle"
      >
        {nodeTitle}
      </text>

      {/* Redirect Button */}
      <g
        className={classNames(classes.node)}
        transform={`translate(${
          paddedWidth - redirectionButtonSize * 1.5
        }, 11)`}
        onClick={event => handleRedirect(event)}
      >
        <rect
          y="-5"
          x="-5"
          width={redirectionButtonSize + 10}
          height={redirectionButtonSize + 10}
          strokeOpacity="0"
          fillOpacity="0"
        />
        <OpenInNewIcon
          width={redirectionButtonSize}
          height={redirectionButtonSize}
          className={classNames(classes.icon)}
        />
      </g>
    </g>
  );
}
